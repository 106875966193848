import styled from 'styled-components';
import graficIcon from '../../assets/icons/grafic-example.svg';
import Trend from 'react-trend';

export const Grafic = ({ statistic }) => (
  <StyledGrafic>
    <Trend
      data={statistic.map(e => e.likes).reverse()}
      gradient={['#F28569']}
      radius={0}
      strokeWidth={14}
      strokeLinecap={'butt'}
    />
  </StyledGrafic>
)

const StyledGrafic = styled.div`
    width: 50px;
    svg {
       height: 12px;
       width: 50px;
    }
`;