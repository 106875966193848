import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { hideModalOnClickOutside } from "../../helpers/hideModalOnClickOutside";
import { useCreateArticle } from "../../hooks/article/useCreateArticle";
import { useUser } from "../../hooks/user/useUser";
import { ArticleCard } from "../ArticleCard/ArticleCard";
import { BackgroundGradient } from "../BackgroundGradient/BackgroundGradient";
import { Input } from "../Input/Input";
import { Logo } from "../Logo/Logo";
import { Button } from "./Button";
import { DownloadFile } from "./DownloadFile";
import { Title } from "./Title";

export const CreateArticle = ({ onPopupMessage }) => {
  const navigate = useNavigate();
  const { createArticle } = useCreateArticle();
  const { user } = useUser();
  const [errors, setErros] = useState({});
  const [imageInput, setImageInput] = useState();
  const [image, setImage] = useState();
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');
  const [loading, setLoading] = useState(false);
  const goingBack = useRef(false);

  const handleChangeImageInput = e => {
    setImageInput(e);
    const reader = new FileReader();
    reader.readAsDataURL(e?.files[0]);
    reader.onload = function () {
      setImage(reader.result);
    }
  }
  const handleChangeDescription = value => setDescription(value);
  const handleChangeLink = value => setLink(value);

  // reset all values
  const handleResetValues = () => {
    setImage(null);
    setDescription('');
    setLink('');
    setLoading(false);
  }

  const handleCloseCreateTerm = () => {
    if (!goingBack.current) {
      goingBack.current = true;
      navigate(-1);
    }
  }

  // reset data on close modal
  useEffect(() => {
    return () => handleResetValues();
  }, [])

  // cheking click outside modal 

  useEffect(() => {
    window.addEventListener("click", (e) => {
      hideModalOnClickOutside(e, "create-article-modal-wrapper", handleCloseCreateTerm);
    }, false);

    return () => window.removeEventListener("click", (e) => {
      hideModalOnClickOutside(e, "create-article-modal-wrapper", handleCloseCreateTerm);
    }, false);
  }, [])

  const handleCreateArticle = async () => {
    setLoading(true)
    const resp = await createArticle({ description, link, image })
    setLoading(false);
    if (resp) {
      onPopupMessage("Статья успешно добавлена");
      navigate('/');
    }
  }

  return (
    <StyledCreateTerm className="create-article-modal-wrapper">
      <Logo />
      <StyledContent>
        <Title title="Добавить статью" />
        <DownloadFile
          imageValue={imageInput}
          onChangeValue={handleChangeImageInput}
        />
        {
          (image || description?.length > 0) &&
          <ArticleCard
            image={image}
            description={description}
            styles="create-article-card"
            noClose
          />
        }
        <Input
          label="Описание"
          value={description}
          onValueChange={handleChangeDescription}
          error={errors?.descripton}
          textarea
          classes="description-textarea"
        />
        <Input
          label="Ссылка"
          value={link}
          onValueChange={handleChangeLink}
          error={errors?.link}
        />
        <Button
          text="Добавить"
          onCreate={handleCreateArticle}
          disabled={!image || description?.length === 0 || link.length === 0 || loading || errors?.name}
        />
      </StyledContent>
      <BackgroundGradient />
    </StyledCreateTerm>
  )
}

const StyledCreateTerm = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #232323;
  height: 100vh;
  z-index: 1000;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 60px;
  overflow: scroll;
  transition: all .5s;
  width: 100%;
  overflow-x: hidden;
  .select{
    margin: 16px 0;
  }
  .description-textarea {
    textarea {
      height: 184px;
      margin-bottom: 16px;
    }
  }
  .create-article-card {
    height: 340px;
  }
`;

const StyledContent = styled.div`
  width: 90%;
  box-sizing: content-box;
  /* width: 80vw; */
  @media (min-width: 360px) {
    width: 320px;
  }
  @media (min-width: 480px) {
    width: 340px;
    padding: 0 10%;
  }
  /* @media (min-width: 1280px) {
    max-width: 1100px;
  } */
`;