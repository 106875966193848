import axios from "axios";
import { useRef, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { baseUrl, headers } from "../../constants/api";
import { CATEGORY_TERMS } from "../../constants/types";

export const useCategoryTerms = () => {
    const client = useQueryClient();
    const { categoryId : categoryID , termId } = useParams();
    
    const currentUserId = atob(localStorage.getItem('CUID'));
    // handle loading more terms
    const [loadingMore, setLoadingMore] = useState(false);
    let perPage = 5;

    const { data: categoryTerms = null } = useQuery(
        [CATEGORY_TERMS, { categoryID, termId }],
        async () => categoryID && (await axios.get(`${baseUrl}/api/terms/get-category-terms`, {
            params: {
                slug: categoryID,
                perPage,
                user_id: currentUserId,
                term_id: termId,
            }
        }))?.data?.response,
    );

    // get category terms 
    const handleGetCategotyTerms = async (categoryId) => {
        perPage = 5;
        const resp = await axios.get(`${baseUrl}/api/terms/get-category-terms`, {
            params: {
                category_id: categoryId,
                perPage,
                page: 1,
                user_id: currentUserId,
                slug: termId,
            }
        })
        client.setQueriesData(CATEGORY_TERMS, resp?.data?.response);
    }

    const handleLoadMoreCategoryTerms = async () => {
        let userTerms = client.getQueriesData(CATEGORY_TERMS)[0][1];
        userTerms = userTerms ?? {};
        setLoadingMore(true);
        const resp = await axios.get(`${baseUrl}/api/terms/get-category-terms`, {
            headers: headers(),
            params: {
                perPage,
                category_id: categoryTerms.data[0]?.category_id,
                page: categoryTerms.current_page + 1,
                user_id: currentUserId,
                term_id: termId,
            }
        })
        const nextPage = resp?.data?.response;
        let updatedTerms = nextPage;
        updatedTerms.data = [...categoryTerms.data, ...nextPage.data];
        client.setQueriesData(CATEGORY_TERMS, updatedTerms);
        setLoadingMore(false);
    }
    return {
        categoryTerms: categoryTerms,
        getCategoriesTerms: handleGetCategotyTerms,
        onLoadMoreCategoryTerms: handleLoadMoreCategoryTerms,
        loadingMoreCategoryTerms: loadingMore,
    }
}

export default useCategoryTerms;