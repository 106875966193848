import { useState } from "react";
import styled from "styled-components";
import dislikeIcon from '../../assets/icons/dislike.svg';
import notDislikeIcon from '../../assets/icons/not-dislike.png';
import { useAddLikeOrDislike } from "../../hooks/term/useAddLikeOrDislike";

export const Dislike = ({ likeStatus, termId, checkIsLogin, disabled, onRefreshData }) => {
  const [updating, setUpdate] = useState(false);
  const { addLikeOrDislike } = useAddLikeOrDislike();

  const handleUpdateLikeStatus = async (data) => {
    if (checkIsLogin()) {
      setUpdate(true);
      const resp = await addLikeOrDislike(data)
      setUpdate(!resp);
      resp && onRefreshData()
    }
  }


  const handleToggleDislike = async () => {
    const currentElement = Number(likeStatus?.element);
    const currentElementType = Number(likeStatus?.type);
    if (currentElement !== 0) {
      await handleUpdateLikeStatus({ termId, element: 0, type: 1 });
    } else {
      let type;
      if (currentElement === 1) {
        type = 1;
      } else {
        type = currentElementType === 1 ? 0 : 1;
      }
      await handleUpdateLikeStatus({ termId, element: 0, type });
    }
  }

  return (
    <StyledDislike onClick={() => (!updating && !disabled) && handleToggleDislike()}
    >
      <StyledIcon active={(Number(likeStatus?.element) === 0) && (Number(likeStatus?.type) === 1)} disabled={disabled}>
        <img src={dislikeIcon} />
        <img src={notDislikeIcon} className='dislike-background' />
      </StyledIcon>
      <span>{likeStatus?.dislikes ?? 0}</span>
    </StyledDislike >
  )
}

const StyledDislike = styled.div`
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 17px;
    line-height: 130%;
    color: #FFFFFF;
    transition: all .5s;
    span {
        margin-left: 13px;
    }
`;

const StyledIcon = styled.div`
  position: relative;
  height: 28px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  .dislike-background {
    transition: all .5s;
    position: absolute;
    left: 0;
    opacity: ${props => (props.active && !props.disabled) ? 0 : 1};
  }
`;

