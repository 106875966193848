import { useState } from "react";
import styled from "styled-components";
import { PopupModal } from "../PopupModal/PopupModal";

export const Links = () => {
  const [showInfo, setShowInfo] = useState(false);

  const handleCloseInfo = () => setShowInfo(false);

  return (
    <>
      <PopupModal
        visible={showInfo}
        onClose={handleCloseInfo}
        htmlText={(<span>
          Сообщить о баге можно c помощью телеграм-бота от Speach <br />
          <a href="https://t.me/Speach_ukBot" target="_blank">
            <StyledButton>перейти в Telegram</StyledButton>
          </a>
          <br />
          Либо же, если вы до сих пор занимаетесь охотой на мамонтов и пещерной живописью — присылайте письмо на
          <a href="mailto:support@speach.uk" style={{ marginLeft: 5 }}>support@speach.uk</a>
        </span>)}
      />
      <StyledLinks>
        <StyledLink href="https://speach.uk/opredelenie-slova/133" target="_blank">
          О сервисе
        </StyledLink>
        <StyledLink onClick={() => setShowInfo(true)}>
          Сообщить о баге
        </StyledLink>
        <StyledLink href="/faq" target="_blank">FAQ</StyledLink>
        <StyledInfo>
          © 2021-2022 Speach Dictionary
        </StyledInfo>
        <StyledInfo href="/term-of-use" target="_blank">
          Условия использования
        </StyledInfo>
        <StyledInfo href="/privacy-policy" target="_blank">
          Политика конфиденциальности
        </StyledInfo>
      </StyledLinks>
    </>
  )
}
const StyledLinks = styled.a`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  @media (min-width: 600px) {
    justify-items: flex-start;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
const StyledLink = styled.a`
  font-weight: 500;
  font-size: 15px;
  line-height: 130%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #F28569;
  margin-bottom: 20px;
  font-family: 'Inter';
  cursor: pointer;
  @media (max-width: 600px) {
    &:nth-child(1) {
      order: 2;
    }
    &:nth-child(2) {
      order: 2;
    }
    &:nth-child(3) {
      order: 1;
    }
  }
`;

const StyledInfo = styled.a`
  font-weight: 400;
  font-size: 13px;
  line-height: 130%;
  color: #D5D2D0;
  order: 4;
  cursor: pointer;
  font-family: 'Inter';
  @media (max-width: 600px) {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledButton = styled.div`
  padding: 17px 74px;
  width: 100%;
  height: 60px;
  background: #F28569;
  border-radius: 10px;
  margin: 24px 0 0;
  font-weight: 600;
  font-size: 17px;
  line-height: 130%;
  letter-spacing: 0.04em;
  color: #FFFFFF; 
  text-align: center;
  cursor: pointer; 
`;