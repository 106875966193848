import { useState } from "react";
import styled from "styled-components";
import { CheckBox } from "../CheckBox/CheckBox";
import { Input } from "../Input/Input";
import { SubmitButton } from "./SubmitButton";
import { SuccessMessage } from "./SuccessMessage";
import { Title } from "./Title";
import { useNavigate } from "react-router-dom";
import { useResetPassword } from "../../hooks/auth/useResetPassword";
import { checkIsCyrillic } from "../../helpers/validation";

export const ChangePassword = () => {
    const { resetPassword } = useResetPassword();
    const [changed, setChanged] = useState(false);
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [rememberPassword, setRememberPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState(null);
    const handleResetErrors = () => setErrors({});

    const handleChangePassword = (value) => {
        setPassword(value);
        setMessage(null);
        if (errors?.passwordConfirmation === 'Пароли не совпадают' || errors.password === 'Пароли не совпадают') {
            handleResetErrors();
        } else {
            setErrors({ ...errors, password: checkIsCyrillic(value, 'Пароль – кириллицу в пароль? Серьезно?') });
        }
    };

    const handleChangePasswordConfirmation = (value) => {
        setPasswordConfirmation(value)
        if (errors?.passwordConfirmation === 'Пароли не совпадают' || errors.password === 'Пароли не совпадают') {
            handleResetErrors();
        } else {
            setErrors({ ...errors, passwordConfirmation: checkIsCyrillic(value, 'Пароль – кириллицу в пароль? Серьезно?') });
        }
    };
    const handleToggleRememberPassword = () => setRememberPassword(!rememberPassword);

    const handleSubmit = async () => {
        setMessage(null)
        if (password === passwordConfirmation) {
            const href = window.location.href;
            const urlParams = new URLSearchParams(href);
            const token = href
                ?.split('?')[1]
                ?.split('&')[0]
                ?.split('=')[1];

            const resp = await resetPassword({
                email: urlParams.get('email'),
                token: token,
                password: password,
                password_confirmation: passwordConfirmation
            })
            if (resp.status == 200) {
                const message = resp.data.response;
                if (message === 'Ваш пароль был сброшен!') {
                    setChanged(true)
                } else {
                    setMessage(resp.data.response)
                }
            }
        } else {
            setErrors({ password: 'Пароли не совпадают', passwordConfirmation: 'Пароли не совпадают' })
        }
    }

    const handleBackLogin = () => navigate('/auth/login');

    return (
        <StyledChangePassword>
            <Title title={changed ? 'Успех!' : 'Смените пароль'} changed={changed} />
            {
                changed
                    ? <SuccessMessage />
                    : <>
                        <Input
                            label="Новый пароль"
                            value={password}
                            onValueChange={handleChangePassword}
                            classes="input"
                            error={errors?.password || message}
                            password
                            onBlur={(e) => e.target.value.length < 8 && setErrors({ ...errors, password: 'Пароль – вряд ли ваш аккаунт будет кому-то нужен, но 8 символов – минимум' })}
                        />
                        <Input
                            label="Повторите еще раз пароль"
                            value={passwordConfirmation}
                            onValueChange={handleChangePasswordConfirmation}
                            classes="input2"
                            error={errors?.passwordConfirmation}
                            onBlur={(e) => e.target.value.length < 8 && setErrors({ ...errors, passwordConfirmation: 'Пароль – вряд ли ваш аккаунт будет кому-то нужен, но 8 символов – минимум' })}
                            password
                            notShow
                        />
                        <CheckBox
                            label={[{ type: 'text', text: 'Запомнить пароль' }]}
                            checked={rememberPassword}
                            onToggle={handleToggleRememberPassword}
                            disabled={password.length === 0 || passwordConfirmation.length === 0}
                        />
                    </>
            }
            <SubmitButton
                text={changed ? 'Вернуться ко входу' : 'Cменить'}
                onChangePassword={() => changed ? handleBackLogin() : handleSubmit()}
                changed={changed}
                disabled={password.length === 0 || passwordConfirmation.length === 0}
            />
        </StyledChangePassword>
    )
}


const StyledChangePassword = styled.div`
    width: 100%;
    .input {
        margin-bottom: 16px;
    }
    .input2 {
        margin-bottom: 15px;
    }
`;