import { useEffect, useState } from "react";
import styled from "styled-components";
import closeIcon from '../../assets/icons/close.svg';
import { Confirm } from "./Confirm";
import { Option } from "./Option";

export const Menu = ({ visible, onClose, options }) => {
    const [confirm, setConfirm] = useState(false);
    const handleCloseConfirm = () => setConfirm(null);

    const handleOptionFunction = (isConfirm, func) =>{
        if(isConfirm) {
            setConfirm({ func: func })
        } else {
            func();
            onClose();
        }
    }
    useEffect(() => {
        !visible && setConfirm(null);
    }, [visible])

    return (
        <StyledMenu visible={visible} className="not-select">
            <StyledCloseButton src={closeIcon} onClick={onClose} />
            {
                confirm
                    ? <Confirm
                        confirmFunc={confirm}
                        onClose={onClose}
                    />
                    : <>
                        {
                            options?.length > 0 &&
                            options.map((opt, i) => (
                                <Option
                                    key={i}
                                    label={opt.label}
                                    active={opt?.active}
                                    onSelectOption={() => handleOptionFunction(opt?.confirm, opt?.func)}
                                />
                            ))
                        }
                    </>
            }
        </StyledMenu>
    )
}

const StyledMenu = styled.div`
    position: absolute;
    background: #4D4A48;
    border: 1px solid #4D4A48;
    backdrop-filter: blur(236px);
    border-radius: 24px;
    padding: 24px;
    right: 0px;
    top: 58px;
    width: 245px;   
    z-index: 10;
    transition: all .2s;
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    opacity: ${props => props.visible ? '1' : '0'};;
`;

const StyledCloseButton = styled.img`
    width: 14px;
    height: 14px;
    position: absolute;
    top: 29px;
    right: 29px;
    cursor: pointer;
`;