import styled from "styled-components";

export const ModeratorCard = ({ login, userId, username }) => (
  <StyledModeratorCard>
    <span>
      {`${login}   ${username ? `( ${username} )` : ''}`}
    </span>
    <div>
      {
        username &&
        <a
          href={`https://telegram.me/${username}`}
          target="_blank">
          Telegram
        </a>
      }
      <a
        href={`/#/profile/${userId}`}
        target="_blank">
        Профиль
      </a>
    </div>
  </StyledModeratorCard>
)

const StyledModeratorCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(153px);
    border-radius: 10px;
    height: 40px;
    padding: 30px 24px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: all .5s;
    span {
        color: #F4F7A1;
    }
    a {
        color: #D5D2D0;
        padding: 0 10px;
        transition: all .3s;
        &:hover {
            color: #F4F7A1;
        }
    }
`;