import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { ADMIN, MODERATOR } from "../../constants/roles";
import { useUpdateUserBio } from "../../hooks/user/useUpdateUserBio";
import { Input } from "../Input/Input";
import { Footer } from "./Footer";
import { Header } from "./Header";

export const ProfileCard = ({
  classes,
  profileName,
  profileRole,
  profileBio,
  profileId,
  profileLikes,
  isProfileBlocked,
  isCurrentUser,
  registrationDate,
  userRole,
  userId,
  userBio,
  adminView,
  activeSection,
  onSectionChange,
  infoStep,
  onAdmining,
  onNextInfoStep,
  onComment
}) => {
  const { updateUserBio } = useUpdateUserBio();
  // user bio
  const [bio, setBio] = useState('');

  // set user/profile bio if it exist
  useEffect(() => {
    setBio(isCurrentUser ? userBio : profileBio);
  }, [userBio, profileBio])

  // change bio input value
  const handleChangeBio = (value) => setBio(value);

  // save bio value on blur
  const handleSaveBio = () => {
    updateUserBio({ user_id: userId, bio })
  }

  return (
    <StyledProfileCard className={classes} isEmpty={isCurrentUser}>
      <Header
        adminView={userRole === ADMIN && !isCurrentUser && profileRole !== ADMIN}
        onAdmining={onAdmining}
        profileName={profileName}
        profileRole={profileRole}
        profileId={profileId}
        profileLikes={profileLikes}
        isProfileBlocked={isProfileBlocked}
        registrationDate={registrationDate}
        onComment={onComment}
      />
      {
        // show if this is current user (editable), or if it's not current user and there is bio (this is viewOnly)
        (isCurrentUser || (!isCurrentUser && bio?.length > 0)) &&
        <Input
          label={(adminView || !isCurrentUser) ? "" : "Био"}
          placeholder="46 лет, милфхантер                                                               inst: maxgalkinru"
          value={bio ?? ''}
          onValueChange={handleChangeBio}
          textarea
          classes="input"
          maxLength={adminView ? null : 100}
          onBlur={handleSaveBio}
          viewOnly={!isCurrentUser}
        />
      }
      {
        isCurrentUser &&
        <Footer
          activeSection={activeSection}
          onSectionChange={onSectionChange}
          moderator={profileRole === MODERATOR}
          admin={profileRole === ADMIN}
          infoStep={infoStep}
          onNextInfoStep={onNextInfoStep}
        />
      }
    </StyledProfileCard>
  )
}

const StyledProfileCard = styled.div`
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(153px);
    border-radius: 10px;
    padding: 20px 30px 24px 20px;
    .input {
       textarea {
        height: 96px;
       }
    }
    ${props => !props.isEmpty && `
        display: flex;
        flex-direction: column;
        justify-content: center;
    `}
    @media(min-width: 768px) {
        padding: 30px 42px 24px 32px;
    }
`;