import styled from "styled-components";
import logoIcon from '../../assets/icons/logo.png';

export const Logo = () => (
  <StyledLogo>
    <img src={logoIcon} alt="logo" />
    <span>peach</span>
  </StyledLogo>
)

const StyledLogo = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    img {
        height: 37px;
        image-rendering: -webkit-optimize-contrast;
    }
    span {
        font-family: 'Montserrat', sans-serif !important;
        margin-left: 2px;
        font-weight: 700;
        font-size: 30px;
        line-height: 24px;
        color: #F4F7A1;
    }
`;