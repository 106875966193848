import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useGetRandomTerm } from "../../hooks/term/useGetRandomTerm"

export const RandomTerm = () => {
  const navigate = useNavigate();
  const { getRandomTerm } = useGetRandomTerm();

  const handleGetRandomTerm = async () => {
    const term = await getRandomTerm();
    if (term && term?.status === 200) {
      const termId = term?.data?.response?.term_id;
      navigate(`/opredelenie-slova/${termId}`);
    }
  }
  return (
    <StylesRandomTerm onClick={handleGetRandomTerm}>
      🎲 Рандом
    </StylesRandomTerm>
  )
}

const StylesRandomTerm = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 130%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #F28569;
  cursor: pointer;
`;


