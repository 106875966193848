import axios from "axios";
import { useQueryClient } from "react-query";
import { baseUrl, headers } from "../../constants/api";
import { CATEGORY_TERMS, PROFILE, TERM, TERMS, USER, USERT_TERMS } from "../../constants/types";
import { checkIsDataExist } from "../../helpers/checkIsDataExist";
import { parseTermStatus } from "../../helpers/term";
import { updatetItem } from "../../helpers/updatetItem";

export const useEditTerm = () => {
    const client = useQueryClient();

    const handleEditTerm = async ({ termId, category_id, title, description, sample }) => {
        const resp = await axios.post(`${baseUrl}/api/term/update-term`, {
          term_id: termId,
          title,
          category_id,
          description,
          sample
        }, {
            headers: headers(),
        })

        if (resp?.status === 200) {
            const updatedTerm = resp?.data?.response;
            // update dashboard terms
            const allTermsData = client.getQueryData(TERMS);
            if (allTermsData) {
                let updatedAllTermsData = allTermsData;
                updatedAllTermsData.data = updatetItem(updatedAllTermsData.data, termId, { ...updatedTerm });
                client.setQueriesData(TERMS, updatedAllTermsData);
            }

            // update user terms
            let userTermsData = client.getQueriesData(USERT_TERMS);
            userTermsData = checkIsDataExist(userTermsData);
            if (userTermsData) {
                let updatedUserTermsData = userTermsData;
                updatedUserTermsData.data = updatetItem(updatedUserTermsData.data, termId, { ...updatedTerm });
                client.setQueriesData(USERT_TERMS, updatedUserTermsData);
            }

            // update category terms
            let categoryTermsData = client.getQueriesData(CATEGORY_TERMS);
            categoryTermsData = checkIsDataExist(categoryTermsData);
            if (categoryTermsData) {
                let updatedCategoryTermsData = categoryTermsData;
                updatedCategoryTermsData.data = updatetItem(updatedCategoryTermsData.data, termId, { ...updatedTerm });
                client.setQueriesData(CATEGORY_TERMS, updatedCategoryTermsData);
            }

            // update user terms
            const currentTermStatus = parseTermStatus(updatedTerm?.verification_status);
            let userData = client.getQueryData(USER);
            if(userData && userData?.suggestion) {
            let updatedUserData = userData;
            // update suggestion terms
            updatedUserData.suggestion[currentTermStatus].data = updatetItem(updatedUserData.suggestion[currentTermStatus].data, termId, { ...updatedTerm });
            // update favorites terms
            updatedUserData.favorites.data = updatetItem(updatedUserData.favorites.data, termId, { ...updatedTerm });
            client.setQueryData(USER, updatedUserData);
            }

            // update term  
            let termData = client.getQueriesData(TERM);
            termData = checkIsDataExist(termData)
            if (termData) {
                let updatedTermData = { ...termData, ...updatedTerm };
                client.setQueriesData(TERM, updatedTermData);
            }
        }
        return 'success';
    }

    return { editTerm: handleEditTerm }
}