import axios from "axios";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { baseUrl, headers } from "../../constants/api";
import { PROFILE } from "../../constants/types";

export const useProfile = () => {
    const { userId } = useParams();

    const { data: profile = null } = useQuery(
        [PROFILE, { userId }],
        async () => (await axios.get(`${baseUrl}/api/users/get-user-by-id`, {
            headers: headers(),
            params: { slug: userId }
        }))?.data?.response,
    );

    return { profile }
}

export default useProfile;