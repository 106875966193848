import { useState } from "react";
import styled from "styled-components";
import { Label } from "./Label";

export const CheckBox = ({
    label,
    checked,
    onToggle,
    classes,
    disabled }) => {
    return (
        <StyledCheckBoxWrapper onClick={onToggle} className={classes}>
            <StyledCheckBox active={checked} disabled={disabled} />
            <Label label={label} disabled={disabled} />
        </StyledCheckBoxWrapper>
    )
}

const StyledCheckBoxWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const StyledCheckBox = styled.div`
    width: 14px;
    height: 14px;
    border: 2px solid ${props => props.disabled ? '#4D4A48' : '#F4F7A1'};
    border-radius: 4px;
    cursor: pointer;
    transition: all .5s;
    flex-shrink: 0;
    ${props => props.active && 'background: #F4F7A1;'}
`;