import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { ArticleCard } from "../components/ArticleCard/ArticleCard";
import { EditTerm } from "../components/EditTerm/EditTerm";
import { GoogleAd } from "../components/GoogleAd/GoogleAd";
import { LoadMore } from "../components/LoadMore/LoadMore";
import { NoTermins } from "../components/NoTermins/NoTermins";
import { Spinner } from "../components/Spinner/Spinner";
import { TermCard } from "../components/TermCard/TermCard";
import { ADMIN } from "../constants/roles";
import { setPageDescription } from "../helpers/setPageDescription";
import { setTabTitle } from "../helpers/setTabTitle";
import { useArticles } from "../hooks/article/useArticles";
import useCategories from "../hooks/categories/useCategories";
import useCategoryTerms from "../hooks/term/useCategoryTerms";
import { useUser } from "../hooks/user/useUser";

export const Category = ({ onPopupMessage }) => {
    const { user } = useUser();
    const { categories } = useCategories();
    const {
        categoryTerms,
        onLoadMoreCategoryTerms,
        loadingMoreCategoryTerms,
    } = useCategoryTerms();
    const { articles } = useArticles();
    const { categoryId } = useParams();
    // edit term by admin modal toggle
    const [editTerm, setEditTerm] = useState(false);
    const [viewWidth, setViewWidth] = useState(null);
    const [scrollOffSet, setScrollOffSet] = useState(0);
    const articlesRef = useRef();

    // geting active category title 
    const handleGetActiveCategory = (slug) => {
        const activeCategoty = categories.find(c => c.slug === slug);
        setTabTitle(`Speach – ${activeCategoty?.title ?? ''}`);
        setPageDescription();
        return activeCategoty?.title ?? ''
    }

    const handleEditTerm = (value) => setEditTerm(value);

    useEffect(() => {
        window.addEventListener('resize', () => setViewWidth(window.innerWidth), false);
        return () => window.addEventListener('resize', () => setViewWidth(window.innerWidth), false)
    }, [])

    const handleCheckScrollOffSet = () => {
        const topOffset = window.pageYOffset || document.documentElement.scrollTop;
        setScrollOffSet(topOffset);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleCheckScrollOffSet, false);
        return () => window.addEventListener('scroll', handleCheckScrollOffSet, false);
    }, [])

    return (
        <>
            {/* <Helmet
                title={`“${term?.title}”— Что Это Значит? Сленг, Определение и Значение Слова | Speach.uk `}
            /> */}
            <EditTerm
                visible={editTerm}
                onClose={() => handleEditTerm(false)}
                onPopupMessage={onPopupMessage}
            />
            <div>
                <StyledDivider>
                    Категория
                    <span> {handleGetActiveCategory(categoryId)}</span> :
                </StyledDivider>
                <div>
                    {
                        !categoryTerms
                            ? <Spinner />
                            : <StyledContent className="container">
                                <StyledTermCards>
                                    {
                                        categoryTerms?.data?.length > 0
                                            ? <>
                                                {
                                                    categoryTerms?.data
                                                        .map((term, i) => (
                                                            <React.Fragment key={i}>
                                                                <TermCard
                                                                    key={i}
                                                                    title={term.title}
                                                                    termId={term.id}
                                                                    slug={term.slug}
                                                                    description={term.description}
                                                                    sample={term?.sample}
                                                                    owner={term.user}
                                                                    ownerId={term?.user_id}
                                                                    ownerLikesCount={term?.user?.likes ?? 0}
                                                                    currentUser={user}
                                                                    onEdit={() => handleEditTerm(term)}
                                                                    isTermOfDay={term?.term_of_day}
                                                                    isFavorite={term?.is_favorite}
                                                                    likeStatus={term?.like_status}
                                                                    lastUpdateDate={term?.created_at}
                                                                    adminView={user?.role_id === ADMIN}
                                                                    onPopupMessage={onPopupMessage}
                                                                />
                                                                {/* {
                                  i === 3 &&
                                  <GoogleAd />
                                } */}
                                                                {
                                                                    i === 0 &&
                                                                    <StyledArticlesInTerms>
                                                                        {
                                                                            articles.length > 0 &&
                                                                            articles
                                                                                ?.slice(0, viewWidth > 768 ? 2 : 1)
                                                                                ?.map((article, i) => (
                                                                                    <React.Fragment key={i}>
                                                                                        <ArticleCard
                                                                                            key={i}
                                                                                            id={article?.id}
                                                                                            image={article?.image}
                                                                                            description={article?.description}
                                                                                            link={article?.link}
                                                                                            onPopupMessage={onPopupMessage}
                                                                                            noClose
                                                                                        />
                                                                                    </React.Fragment>
                                                                                ))
                                                                        }
                                                                    </StyledArticlesInTerms>
                                                                }
                                                            </React.Fragment>
                                                        ))
                                                }
                                            </>
                                            : <NoTermins />
                                    }
                                    {
                                        categoryTerms?.current_page < categoryTerms?.last_page &&
                                        <LoadMore
                                            onClick={onLoadMoreCategoryTerms}
                                            loading={loadingMoreCategoryTerms}
                                        />
                                    }
                                </StyledTermCards>
                                <StyledArticles
                                    scrollOffSet={scrollOffSet}
                                    ref={articlesRef}
                                    wrapperWidth={articlesRef.current?.clientWidth}
                                >
                                    {
                                        articles.length > 0 ?
                                            articles
                                                ?.slice(0, 2)
                                                ?.map((article, i) => (
                                                    <ArticleCard
                                                        key={i}
                                                        id={article?.id}
                                                        image={article?.image}
                                                        description={article?.description}
                                                        link={article?.link}
                                                        onPopupMessage={onPopupMessage}
                                                        noClose
                                                        styles={i === 1 && "second-article"}
                                                    />
                                                ))
                                            : null
                                    }
                                </StyledArticles>
                            </StyledContent>
                    }
                </div>
            </div>
        </>
    )
}

const StyledContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  @media(min-width: 1200px) {
    grid-template-columns: 1fr 30%;
  }
`;

const StyledTermCards = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 13px;
  @media (min-width: 480px) {
    margin-top: 23px;
  }
  @media (min-width: 768px) {
    margin-top: 26px;
  }
  @media (min-width: 1000px) {
    margin-top: 32px;
  }
`;

const StyledDivider = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  color: #FFFFFF;
  margin: 40px 20px 24px;
  text-align: center;
  span {
   color: #F4F7A1;
  }
  @media(min-width: 480px) {
    font-size: 32px;
    margin: 40px auto 24px 0;
  }
`;


const StyledArticles = styled.div`
  display: none;
  grid-auto-rows: 250px;
  margin-top: 32px;
  gap: 16px;
  min-height: 800px;
  overflow: hidden;
  @media(max-width: 1300px) {
    ${props => props.scrollOffSet > 450 && `
      .second-article {
        transition: all .3s;
        position: fixed;
        top: 160px;
        height: 250px;
        width: ${props.wrapperWidth}px;
      }
    `}
  }
  @media(min-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr;
  }
  @media(min-width: 1300px) {
    grid-auto-rows: 360px;
    ${props => props.scrollOffSet > 540 && `
    .second-article {
      position: fixed;
      top: 160px;
      height: 360px;
      width: ${props.wrapperWidth}px;
    }
  `}
  }
`;


const StyledArticlesInTerms = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 280px;
  gap: 8px;
  width: 100%;
  @media(min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media(min-width: 1200px) {
    display: none;
  }
`;