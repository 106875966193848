import axios from "axios";
import { useQueryClient } from "react-query";
import { baseUrl, headers } from "../../constants/api";
import { CATEGORY_TERMS, PROFILE, TERM, TERMS, USER, USERT_TERMS } from "../../constants/types";
import { checkIsDataExist } from "../../helpers/checkIsDataExist";
import { updatetItem } from "../../helpers/updatetItem";

export const useToggleTermOfDay = () => {
    const client = useQueryClient();

    const handleToggleTermOfDay = async ({ termId, status }) => {
        const resp = await axios.post(`${baseUrl}/api/terms/term-of-day`, null, {
            headers: headers(),
            params: {
                term_id: termId,
                status
            }
        })

        if (resp?.status === 200) {
            const termOfDay = Number(resp?.data?.response?.term_of_day) ?? status;

            // update dashboard terms
            const allTermsData = client.getQueryData(TERMS);
            if (allTermsData) {
                let updatedAllTermsData = allTermsData;
                updatedAllTermsData.data = updatedAllTermsData.data.map(term => term?.id == termId ? ({ ...term, term_of_day: termOfDay }) : {...term, term_of_day: 0});
                client.setQueriesData(TERMS, updatedAllTermsData);
            }

            // update user terms
            let userTermsData = client.getQueriesData(USERT_TERMS);
            userTermsData = checkIsDataExist(userTermsData);
            if (userTermsData) {
                let updatedUserTermsData = userTermsData;
                updatedUserTermsData.data = updatedUserTermsData.data.map(term => term?.id == termId ? ({ ...term, term_of_day: termOfDay }) : {...term, term_of_day: 0})
                client.setQueriesData(USERT_TERMS, updatedUserTermsData);
            }

            // update category terms
            let categoryTermsData = client.getQueriesData(CATEGORY_TERMS);
            categoryTermsData = checkIsDataExist(categoryTermsData);
            if (categoryTermsData) {
                let updatedCategoryTermsData = categoryTermsData;
                updatedCategoryTermsData.data = updatedCategoryTermsData.data.map(term => term?.id == termId ? ({ ...term, term_of_day: termOfDay }) : {...term, term_of_day: 0})
                client.setQueriesData(CATEGORY_TERMS, updatedCategoryTermsData);
            }

            // update term  
            let termData = client.getQueriesData(TERM);
            termData = checkIsDataExist(termData)
            if (termData) {
                let updatedTermData = termData;
                updatedTermData.term_of_day = termOfDay;
                client.setQueriesData(TERM, updatedTermData);
            }
        }
    }

    return { toggleTermOfDay: handleToggleTermOfDay }
}