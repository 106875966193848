import { useEffect, useState } from "react";
import styled from "styled-components";
import { Message } from "./Message";

export const InfoSteps = ({ onNextInfoStep }) => (
    <StyledInfoSteps onClick={onNextInfoStep} />
)

const StyledInfoSteps = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(35, 35, 35, 0.94);
    z-index: 10;
`;