import styled from "styled-components";
import approveIcon from '../../assets/icons/approve.svg';
import questionIcon from '../../assets/icons/question.svg';
import notAprroveIcon from '../../assets/icons/not-approve.svg';
import { ModerationInfo } from "./ModerationInfo";
import { Step2 } from "../InfoSteps/Step2";
import { Step3 } from "../InfoSteps/Step3";
import { useModerationTerm } from "../../hooks/term/useModerationTerm";
import { APPROVE, EXPECTED, REJECTED } from "../../constants/suggestionStatus";

export const Moderation = ({
    termId,
    aproved,
    question,
    notApproved,
    onShowModerationInfo,
    infoStep,
    onNextInfoStep,
    admin,
    onPopupMessage,
    onTermRemove
}) => {
    const { moderationTerm } = useModerationTerm();

    const handleModerationTerm = async(data) => {
        const resp = await moderationTerm(data);
        if(resp === 'success') {
            onTermRemove();
        }
    }

    return (
        <StyledModeration className={(infoStep === 2 || infoStep === 3) && "info-step-2"}>
            <StyledItem
                onClick={() => admin ?
                    onShowModerationInfo({ moderators: aproved?.moderators, type: APPROVE })
                    : handleModerationTerm({ termId, status: 1 })
                }
            >
                <img src={approveIcon} alt="approve term icon" />
                <span>{aproved?.sum ?? ''}</span>
            </StyledItem>
            <StyledItem
                onClick={() => admin
                    ? onShowModerationInfo({ moderators: question?.moderators, type: EXPECTED })
                    : handleModerationTerm({ termId, status: 2 })}
            >
                <img src={questionIcon} alt="term on question" />
                <span>{question?.sum ?? ''}</span>
            </StyledItem>
            <StyledItem
                onClick={() => admin
                    ? onShowModerationInfo({ moderators: notApproved?.moderators, type: REJECTED })
                    : handleModerationTerm({ termId, status: 3 })}
            >
                <img src={notAprroveIcon} alt="don't approve term icon" />
                <span>{notApproved?.sum ?? ''}</span>
            </StyledItem>
            <Step2 visible={infoStep === 2} onNextInfoStep={onNextInfoStep} />
            <Step3 visible={infoStep === 3} onNextInfoStep={onNextInfoStep} />
        </StyledModeration>
    )
}

const StyledModeration = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    img {
        cursor: pointer;
        height: 15px;
    }
    span {
        margin: 0 10px 0 5px;
        font-weight: 700;
        font-size: 14px;
        line-height: 130%;
        color: #FFFFFF;
    }
    @media(min-width: 468px) {
        img {
            height: 20px;
        }
    }
    @media(min-width: 660px) {
        font-size: 17px;
        img {
            height: auto;
        }
        span {
            margin: 0 20px 0 10px;
        }
    }
`;

const StyledItem = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
`;