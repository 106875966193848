import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { emailValidation } from "../../helpers/validation";
import { useForgotPassword } from "../../hooks/auth/useForgotPassword";
import { Input } from "../Input/Input";
import { Description } from "./Description";
import { SubmitButton } from "./SubmitButton";
import { Title } from "./Title";

export const ResetPassword = () => {
    const navigate = useNavigate();
    const { forgotPassword } = useForgotPassword();
    const [sended, setSended] = useState(false);
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);

    const handleEmailValidation = () => setError(emailValidation(email))

    // change email input
    const handleChangeEmail = (value) => {
        setEmail(value);
        setMessage(null)
        error && handleEmailValidation(value);
    };

    const handleSubmit = async () => {
        const resp = await forgotPassword({ email });
        if (resp.status === 200) {
            const message = resp.data.response;
            if (message === 'Ссылка на сброс пароля была отправлена!') {
                setSended(true)
            } else {
                setMessage(message)
            }
        } else {
            setError(resp?.data?.errors?.email[0])
        }
    }

    const handleToLogin = () => navigate('/auth/login');

    return (
        <StyledResetPassword>
            <Title
                title={sended ? "Инструкция выслана!" : "Сбросить пароль"}
                sended={sended}
            />
            <Description sended={sended} email={email} />
            {
                !sended
                && <Input
                    label="Email"
                    value={email}
                    onValueChange={handleChangeEmail}
                    error={error || message}
                    onBlur={handleEmailValidation}
                />
            }
            <SubmitButton
                text={sended ? "Хорошо, спасибо" : "Отправить"}
                disabled={!sended && email.length == 0 || error}
                onSubmit={() => sended ? handleToLogin() : handleSubmit()}
            />
        </StyledResetPassword>
    )
}

const StyledResetPassword = styled.div`
    width: 100%;
`;