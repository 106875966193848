import { useState } from "react";
import styled from "styled-components";
import downloadIcon from "../../assets/icons/card-download.png";

import { DownloadTermCard } from "../DownloadTermCard/DownloadTermCard";

export const DownloadButton = ({
  termId,
  title,
  description,
  sample,
  owner,
  ownerLikesCount,
  onPopupMessage
}) => {
  const [open, setOpen] = useState(false);

  const handleCloseModal = () => {
    setOpen(false);
    document.querySelector("body").style.overflow = "unset";
  };

  return (
    <>
      {
        open &&
        <DownloadTermCard
          onClose={handleCloseModal}
          termId={termId}
          title={title}
          description={description}
          sample={sample}
          owner={owner}
          ownerLikesCount={ownerLikesCount}
          onPopupMessage={onPopupMessage}
        />
      }
      <StyledDownloadButton onClick={() => setOpen(true)}>
        <img src={downloadIcon} alt="" />
        <div>
          Скачать эту карточку как фото
        </div>
      </StyledDownloadButton>
    </>
  )
}

const StyledDownloadButton = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  padding-right: 2px;
  cursor: pointer;
  border:  1px solid rgba(244, 247, 161, 0.11);;
  border-radius: 3px;
  transition: all .3s;
  position: relative;
  img {
    height: 25.5px;
  }
  div {
    display: none;
    position: absolute;
    font-weight: 400;
    font-size: 10px;
    line-height: 140%;
    color: #F4F7A1;
    top: -30px;
    right: 0;
    width: 100px;
    text-align: right;
  }
  &:hover {
    border: 1px solid #f4f7a1;
    div {
      display: block;
    }
  }
`;