import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Navigation = () => (
    <StyledNavigation>
        {/* <NavLink to="/category/12" className="more-link">Brainfood</NavLink> */}
        <NavLink to="/category/english">English</NavLink>
        <NavLink to="/category/gen-z">Gen Z</NavLink>
        <NavLink to="/category/drugs">Drugs</NavLink>
        <NavLink to="/category/18+">18+</NavLink>
        <NavLink to="/category/money" className="more-link">Money</NavLink>
        <NavLink to="/categories">Все категории</NavLink>
    </StyledNavigation>
)

const StyledNavigation = styled.div`
  display: none;
  align-items: center;
  .more-link {
    display: none;
  }
  a {
    font-weight: 400;
    font-size: 15px;
    line-height: 130%;
    letter-spacing: 0.04em;
    color: #F28569;
    transition: all .3s;
    &:not(:last-child) {
        margin-right: 20px;
    }
    &:hover {
      transform: translateY(-5px);
    }
  }
  @media (min-width: 870px) {
    display: flex;
  }
  @media(min-width: 1200px) {
    .more-link {
      display: inline-block;
    }
    a {
      &:not(:last-child) {
        margin-right: 28px;
    }
    }
  }
`;