import { useState } from "react";
import styled from "styled-components";
import { APPROVE, EXPECTED, REJECTED } from "../../constants/suggestionStatus";
import { FilterOption } from "./FilterOption";

export const FilterSuggestedTermsBar = ({ activeOption, onChangeOption }) => {
    const options = [
        { label: 'Одобренные', value: APPROVE },
        { label: 'Не одобренные', value: REJECTED },
        { label: 'В ожидании', value: EXPECTED }
    ]

    return (
        <StyledFilterOfferedTermsBar>
            {
                options.map((opt, i) => (
                    <FilterOption
                        key={i}
                        text={opt.label}
                        active={activeOption === opt.value}
                        onSelect={() => onChangeOption(opt.value)}
                    />
                ))
            }
        </StyledFilterOfferedTermsBar>
    )
}

const StyledFilterOfferedTermsBar = styled.div`
    width: 90%;
    margin:  0 auto 16px;
    margin-bottom: 16px;
    height: 66px;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 10px;
    padding: 14px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media(min-width: 480px) {
        width: 100%;
    }
    @media(min-width: 768px) {
        padding: 14px 161px;
    }
`;