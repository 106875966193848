import axios from "axios";
import { useQueryClient } from "react-query";
import { baseUrl, headers } from "../../constants/api";
import { CATEGORY_TERMS, PROFILE, TERM, TERMS, USER, USERT_TERMS } from "../../constants/types";
import { checkIsDataExist } from "../../helpers/checkIsDataExist";
import { updatetItem } from "../../helpers/updatetItem";
import { useUpdateProfileStatistics } from "../profile/useUpdateProfileStatistics";

export const useAddLikeOrDislike = () => {
    const client = useQueryClient();
    const { updateProfileStatics } = useUpdateProfileStatistics();

    const handleAddLikeOrDislike = async ({ termId, type, element }) => {
        try {
            const resp = await axios.post(`${baseUrl}/api/term/add-like-or-dislike`, null, {
                headers: headers(),
                params: {
                    term_id: termId,
                    type,
                    element
                }
            })

            if (resp?.status === 200) {
                const likeStatus = resp?.data?.response;

                // update dashboard terms
                const allTermsData = client.getQueryData(TERMS);
                if (allTermsData) {
                    let updatedAllTermsData = allTermsData;
                    updatedAllTermsData.data = updatetItem(updatedAllTermsData.data, termId, { like_status: likeStatus });
                    client.setQueriesData(TERMS, updatedAllTermsData);
                }

                // update user terms
                let userTermsData = client.getQueriesData(USERT_TERMS);
                userTermsData = checkIsDataExist(userTermsData);
                if (userTermsData) {
                    let updatedUserTermsData = userTermsData;
                    updatedUserTermsData.data = updatetItem(updatedUserTermsData.data, termId, { like_status: likeStatus });
                    client.setQueriesData(USERT_TERMS, updatedUserTermsData);
                }

                // update user favorites terms
                let userData = client.getQueriesData(USER);
                userData = checkIsDataExist(userData);
                if (userData) {
                    let updateUserData = userData;
                    updateUserData.favorites.data = updatetItem(updateUserData.favorites.data, termId, { like_status: likeStatus });
                    client.setQueriesData(USER, updateUserData);
                }

                // update category terms
                let categoryTermsData = client.getQueriesData(CATEGORY_TERMS);
                categoryTermsData = checkIsDataExist(categoryTermsData);
                if (categoryTermsData) {
                    let updatedCategoryTermsData = categoryTermsData;
                    updatedCategoryTermsData.data = updatetItem(updatedCategoryTermsData.data, termId, { like_status: likeStatus });
                    client.setQueriesData(CATEGORY_TERMS, updatedCategoryTermsData);
                }

                // update term  
                let termData = client.getQueriesData(TERM);
                termData = checkIsDataExist(termData);
                if (termData && termData?.id === termId) {
                    let updatedTermData = termData;
                    updatedTermData.like_status = likeStatus;
                    client.setQueriesData(TERM, updatedTermData);
                }

                // update profile statistics 
                updateProfileStatics();
            }
            return 'success';
        } catch {
            return 'error'
        }

    }

    return { addLikeOrDislike: handleAddLikeOrDislike }
}