import styled from "styled-components";
import { useRemoveTerm } from "../../hooks/term/useRemoveTerm";
import { useToggleTermOfDay } from "../../hooks/term/useToggleTermOfDay";
import { Dropdown } from "../Dropdown/Dropdown";
import { Admining } from "./Admining";
import { Bookmark } from "./Bookmark";
import { DailyTerm } from "./DailyTerm";
import { DownloadButton } from "./DownloadButton";
import { Moderation } from "./Modetation";
import { Title } from "./Title";


export const Header = ({
  title,
  description,
  sample,
  owner,
  ownerLikesCount,
  isFavorite,
  termId,
  slug,
  isOwner,
  onEdit,
  isTermOfDay,
  user,
  currentUserId,
  moderator,
  admin,
  adminView,
  infoStep,
  onAdmining,
  verificationStatus,
  onTermRemove,
  onNextInfoStep,
  onPopupMessage,
  onComment,
  notAproved,
  mainCard
}) => {
  const { toggleTermOfDay } = useToggleTermOfDay();
  const { removeTerm } = useRemoveTerm()
  const termOfDayStatus = Number(isTermOfDay) === 1;

  const handleRemoveTerm = async () => {
    const resp = await removeTerm({ termId });
    resp === 'success' && onTermRemove();
  }

  const dropdownOptions = [
    {
      label: "Термин дня",
      func: () => toggleTermOfDay({ termId, status: termOfDayStatus ? 0 : 1 }),
      active: termOfDayStatus,
      confirm: true
    },
    { label: "Редактировать", func: () => onEdit() },
    {
      label: "Удалить",
      func: handleRemoveTerm,
      confirm: true
    }
  ]

  return (
    <StyledHeader>
      <StyledTitles>
        <Title
          title={title}
          termId={termId}
          slug={slug}
          notAproved={notAproved}
          mainCard={mainCard}
        />
        {termOfDayStatus && <DailyTerm />}
      </StyledTitles>
      <div className="buttons-wrapper">
        <div className="download-header-btn">
          <DownloadButton
            termId={termId}
            title={title}
            description={description}
            sample={sample}
            owner={owner}
            ownerLikesCount={ownerLikesCount}
            onPopupMessage={onPopupMessage}
          />
        </div>
        {
          !user && !moderator && !admin && !adminView &&
          <Bookmark
            termId={termId}
            isFavorite={isFavorite}
            onTermRemove={onTermRemove}
            currentUserId={currentUserId}
            onPopupMessage={onPopupMessage}
          />
        }
        {
          moderator &&
          <Moderation
            termId={termId}
            infoStep={infoStep}
            onNextInfoStep={onNextInfoStep}
            onPopupMessage={onPopupMessage}
            onTermRemove={onTermRemove}
          />}

        {admin
          &&
          <Admining
            termId={termId}
            verificationStatus={verificationStatus}
            onEdit={onEdit}
            onAdmining={onAdmining}
            onTermRemove={onTermRemove}
            onPopupMessage={onPopupMessage}
            onComment={onComment}
          />}
        {
          adminView
          && <Dropdown
            options={dropdownOptions}
          />
        }
      </div>
    </StyledHeader>
  )
}

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .buttons-wrapper {
    display: flex;
    align-items: center;
  }
  .download-header-btn {
    display: none;
  }
  @media(min-width: 480px ){
    .download-header-btn {
      display: block;
    }
  }
`;

const StyledTitles = styled.div`
  display: flex;
  align-items: center;
`;