import styled from "styled-components";
import { socmediaColors } from "./socmediaColors";

export const SocMediaItem = ({ logo, link, type }) => (
    <StyledSocMediaItem
        href={link}
        type={type}
        target="_blank"
    >
        <img src={logo} />
    </StyledSocMediaItem>
)

const StyledSocMediaItem = styled.a`
    height: 40px;
    width: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => socmediaColors[props.type] ?? '#FFFF'};
    flex-shrink: 0;
    transition: all .5s;
    &:hover {
        transform: translateY(-8px);
        opacity: .5;
    }
`;