import { Autorization } from "./pages/Autorization";
import { Route, Routes, useLocation } from "react-router";
import { BackgroundGradient } from "./components/BackgroundGradient/BackgroundGradient";
import { TermsOfUse } from "./pages/TermsOfUse";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { Dashboard } from "./pages/Dashboard";
import { Categories } from "./pages/Categories";
import { NotFound } from "./pages/NotFound";
import { Termin } from "./pages/Termin";
import { Faq } from "./pages/Faq";
import { ProfileModeratorSteps } from "./pages/ProfileModeratorSteps";
import { Profile } from "./pages/Profile";
import { PagesWrapper } from "./pages/PagesWrapper";
import { StatisicsCards } from "./components/StatisicsCards/StatisicsCards";
import { useEffect, useState } from "react";
import { Popup } from "./components/Popup/Popup";
import { useUser } from "./hooks/user/useUser";
import { useLogout } from "./hooks/auth/useLogout";
import { Category } from "./pages/Category";
import { CreateTerm } from "./components/CreateTerm/CreateTerm";
import { Create } from "./pages/Create";
import { CreateArticle } from "./components/CreateArticle/CreateArticle";

const App = () => {
  const location = useLocation();
  const { user } = useUser();
  const { logout } = useLogout();
  const [authSuccess, setAuthSuccess] = useState(false)
  const [popupMessage, setPopupMessage] = useState('');

  const handlePopupMessage = (message, noIcon) => {
    setPopupMessage({ message, noIcon });
    setAuthSuccess(true);
    setTimeout(() => setAuthSuccess(false), 3000)
  }


  // on change page/url sroll to top
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if(window.location.origin  === "https://www.speach.uk" ) {
      window.location.replace("https://speach.uk");
    }
  }, [location])

  useEffect(() => {
    if (user && user?.status === 0) {
      logout();
      handlePopupMessage("Вас заблокировано", true);
    }
  }, [user])

  return (
    <div className="app">
      <Popup
        visible={authSuccess}
        message={popupMessage?.message}
        noIcon={popupMessage?.noIcon}
      />
      <Routes >
        <Route path="/auth/:authType" element={<Autorization onPopupMessage={handlePopupMessage} />} />
        <Route path="/term-of-use" element={<TermsOfUse />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/termin" element={<Termin />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/categories-moderator" element={<Categories moderator />} />
        <Route path="/categories-admin" element={<Categories admin />} />
        <Route path="/profile-moderator-info/:userId" element={<ProfileModeratorSteps />} />
        <Route path="*" element={
          <PagesWrapper onPopupMessage={handlePopupMessage}>
            <>
              <StatisicsCards />
              <Routes >
                <Route exact path="/" element={<Dashboard onPopupMessage={handlePopupMessage} />} />
                <Route path="/success-telegram" element={<Dashboard onPopupMessage={handlePopupMessage}  />} />
                <Route path="/what-registration" element={<Dashboard onPopupMessage={handlePopupMessage}  />} />
                <Route path="/categories" element={<Categories onPopupMessage={handlePopupMessage} />} />
                <Route path="/category/:categoryId" element={<Category onPopupMessage={handlePopupMessage} />} />
                <Route path="/profile/:userId" element={<Profile onPopupMessage={handlePopupMessage} />} />
                <Route path="/opredelenie-slova/:termId" element={<Termin onPopupMessage={handlePopupMessage} />} />
                <Route path="/not-found/:termTitle" element={<NotFound onPopupMessage={handlePopupMessage} />} />
                <Route path="/create" element={<Create/>} />
                <Route path="/create-term" element={<CreateTerm onPopupMessage={handlePopupMessage} />} />
                <Route path="/create-article" element={<CreateArticle onPopupMessage={handlePopupMessage} />} />
                <Route path="/create-term/:termTitle" element={<CreateTerm />} />
                <Route path="*" element={<NotFound notTerm />} />
              </Routes >
            </>
          </PagesWrapper>
        } />
      </Routes >
      <BackgroundGradient />

    </div>
  )
}

export default App;
