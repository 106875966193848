import axios from "axios";
import { useQuery } from "react-query";
import { baseUrl, headers } from "../../constants/api";
import { CATEGORIES } from "../../constants/types";

export const useCategories = () => {
    const { data: categories = [] } = useQuery(
        [CATEGORIES],
        async () => (await axios.get(`${baseUrl}/api/term-categories/get-categories`)
        )?.data?.response?.data?.sort((a, b) => a.id - b.id),
    );
    
    return { categories }
}

export default useCategories;