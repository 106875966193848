import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useUser } from "../../hooks/user/useUser";
import { Bookmark } from "./Bookmark";
import { Date } from "./Date";
import { Dislike } from "./Dislike";
import { DownloadButton } from "./DownloadButton";
import { Like } from "./Like";
import { Moderation } from "./Modetation";
import { UserName } from "./UserName";

export const Footer = ({
  title,
  description,
  sample,
  owner,
  isFavorite,
  onTermRemove,
  ownerId,
  ownerLikesCount,
  termId,
  lastUpdateDate,
  notAproved,
  moderator,
  admin,
  currentUserId,
  likeStatus,
  moderatorsRating,
  onShowModerationInfo,
  adminView,
  onPopupMessage,
  onRefreshData
}) => {
  const navigate = useNavigate();
  // user data for check is login
  const { user } = useUser();
  const token = localStorage.getItem('token');

  const handleCheckIsLogin = () => {
    if (user || token) {
      return true;
    } else {
      const currentPathName = window.location.href.split('#')[1];
      localStorage.setItem('prevUrl', currentPathName);
      navigate('/auth/login');
      onPopupMessage('Для оценки поста нужна аутентификация');
    }
  };

  return (
    <StyledFooter notAproved={notAproved} moderator={moderator}>
      <StyledInfo>
        <UserName
          owner={owner}
          ownerId={ownerId}
          ownerLikesCount={ownerLikesCount}
        />
        <Date lastUpdateDate={lastUpdateDate} />
      </StyledInfo>
      {
        !moderator && !admin &&
        <StyledButtons>
          <Dislike
            likeStatus={likeStatus}
            termId={termId}
            checkIsLogin={handleCheckIsLogin}
            disabled={notAproved}
            onRefreshData={onRefreshData}
          />
          <Like
            likeStatus={likeStatus}
            termId={termId}
            checkIsLogin={handleCheckIsLogin}
            disabled={notAproved}
            onRefreshData={onRefreshData}
          />
          {
            adminView && ownerId !== currentUserId &&
            <Bookmark
              classes="bookmark-dashboard-admin"
              termId={termId}
              isFavorite={isFavorite}
              onTermRemove={onTermRemove}
              currentUserId={currentUserId}
              onRefreshData={onRefreshData}
            />
          }
          <div className="download-btn">
            <DownloadButton
              termId={termId}
              title={title}
              description={description}
              sample={sample}
              owner={owner}
              ownerLikesCount={ownerLikesCount}
              onPopupMessage={onPopupMessage}
            />
          </div>
        </StyledButtons>
      }
      {
        admin &&
        <Moderation
          termId={termId}
          aproved={moderatorsRating?.approve}
          question={moderatorsRating?.pending}
          notApproved={moderatorsRating?.reject}
          onShowModerationInfo={onShowModerationInfo}
          admin
        />
      }
    </StyledFooter>
  )
}
const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
  ${props => props.moderator && 'justify-content: center !important;'}
`;

const StyledInfo = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 480px) {
    margin-bottom: 16px;
  }
`;

const StyledButtons = styled.div`
  display: flex;
  align-items: center;
  .bookmark-dashboard-admin {
    margin-left: 20px;
  }
  .download-btn { 
    display: none;
    margin-right: -20px;
  }
  @media (max-width: 480px) {
    width: 100%;
    .download-btn {
      display: block;
      margin-left: auto;
    }
  }
`;