import { useState } from "react";
import styled from "styled-components";
import loadingIcon from "../../assets/icons/loading.svg";

export const LoadMore = ({ onClick, loading }) => (
    <StyledLoadMore
        onClick={onClick}
        loading={loading ?? null}
    >
        <img src={loadingIcon} alt="loading more spinner" />
        <span>Загрузить еще</span>
    </StyledLoadMore>
)

const StyledLoadMore = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    width: max-content;
    margin: 50px auto 0;
    border: 2px solid ${props => props.loading ? '#FFFFFF' : '#F4F7A1'};
    border-radius: 87px;
    padding: 12px 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #FCFCFD;
    cursor: pointer;
    transition: all .5s;
    ${props => !props.loading && `
         &:hover {
            background: #F4F7A1;
            color: #232323;
        }
     `
    }
    img {
        display: ${props => props.loading ? 'block' : 'none'};
        margin-right: 13.33px;
        animation: spinner 2s linear infinite, display 1s;
    }
    @keyframes spinner {
        to {
            transform: rotate(360deg);
        }
    }
`;