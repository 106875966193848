import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Grafic } from "./Grafic";

export const Card = ({ termId, title, procent, statistic }) => (
  <NavLink to={`/opredelenie-slova/${termId}`}>
    <StyledCard>
      <StyledTitle>{title}</StyledTitle>
      <Grafic statistic={statistic} />
      <StyledProcent>
        +{procent}
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 30 24" fill="none">
          <path d="M27.6867 2.12177C26.9537 1.44911 26.0833 0.915505 25.1254 0.551444C24.1675 0.187383 23.1407 0 22.1038 0C21.0669 0 20.0402 0.187383 19.0822 0.551444C18.1243 0.915505 17.254 1.44911 16.5209 2.12177L14.9996 3.51714L13.4783 2.12177C11.9976 0.763676 9.98941 0.00070395 7.89541 0.000703964C5.80142 0.000703978 3.79319 0.763676 2.31251 2.12177C0.831836 3.47987 1.56015e-08 5.32185 0 7.24249C-1.56015e-08 9.16313 0.831836 11.0051 2.31251 12.3632L3.83382 13.7586L14.9996 24L26.1654 13.7586L27.6867 12.3632C28.4201 11.6909 29.0019 10.8926 29.3988 10.0139C29.7957 9.13531 30 8.19356 30 7.24249C30 6.29142 29.7957 5.34967 29.3988 4.47104C29.0019 3.59241 28.4201 2.79412 27.6867 2.12177Z" fill="#F28569" />
        </svg>
      </StyledProcent>
    </StyledCard>
  </NavLink>
)

const StyledCard = styled.div`
  display: flex;
  height: 42px;
  padding: 10px 12px;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(153px);
  border-radius: 10px;
  font-size: 17px;
  line-height: 130%;
  margin-left: 10px;
  -webkit-touch-callout: none;
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none; 
  transition: all .5s;
  &:hover {
    background:  #f4f7a1;
    div {
      color: #232323 !important;
    }
  }
`;

const StyledTitle = styled.div`
  font-weight: 600;
  color: #F4F7A1;
  margin-right: 8px;
  width: max-content;
`;

const StyledProcent = styled.div`
  color: #D5D2D0;
  margin-left: 8px;
  svg {
    height: 13px;
    margin-left: 2px;
  }
`;