import { useParams } from "react-router-dom";
import styled from "styled-components";
import { NotFoundCard } from "../components/NotFoundCard/NotFoundCard";

export const NotFound = ({ notTerm, onPopupMessage }) => {
  const { termTitle } = useParams();
  return (
    <StyledContent>
      <NotFoundCard
        termTitle={termTitle}
        notTerm={notTerm}
        onPopupMessage={onPopupMessage}
      />
    </StyledContent>
  )
}

const StyledContent = styled.div`
  margin-top: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 480px) {
    margin-top: 34px;
  }
  @media (min-width: 768px) {
    margin-top: 40px;
  }
`;