import styled from "styled-components";
import { useModerators } from "../../hooks/admin/useModerators";
import { Spinner } from "../Spinner/Spinner";
import { EmptyMessage } from "./EmptyMessage";
import { ModeratorCard } from "./ModeratorCard";

export const ModeratorsTable = () => {
    const { moderators } = useModerators();
    return (
        <div>
            {
                moderators
                    ? <>
                        {
                            moderators?.length > 0
                                ? <>
                                    {
                                        moderators.map((moderator, i) => (
                                            <ModeratorCard
                                                key={i}
                                                login={moderator?.login}
                                                userId={moderator?.id}
                                                username={moderator?.username}
                                            />
                                        ))
                                    }
                                </>
                                : <EmptyMessage />
                        }
                    </>
                    : <Spinner />
            }
        </div>
    )
}