import axios from "axios"
import { baseUrl, headers } from "../../constants/api"

export const useUpdateUserBio = () => {
    const handleUpdateUserBio = ({ user_id, bio }) => {
        const resp = axios.post(`${baseUrl}/api/user/update-user-bio`, null, {
            headers: headers(),
            params: { user_id, bio }
        })
    }

    return { updateUserBio: handleUpdateUserBio }
}