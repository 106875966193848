import styled from "styled-components";
import { Option } from "./Option";

export const Confirm = ({ confirmFunc, onClose }) => (
    <>
        <Option
            label="Ок"
            onSelectOption={() => { confirmFunc.func(); onClose(); }}
        />
        <Option
            label="Нет"
            onSelectOption={onClose}
        />
    </>
)