import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { getUserColor } from "../../helpers/user";

export const UserName = ({ owner, ownerId, ownerLikesCount }) => (
    <NavLink to={`/profile/${owner.name}`}>
        <StyledUserName color={getUserColor(owner?.role_id, ownerLikesCount)}>
            {owner?.name}
        </StyledUserName>
    </NavLink>
)

const StyledUserName = styled.div`
    font-weight: 600;
    font-size: 15px;
    line-height: 130%;
    color: ${props => props.color};
    margin-right: 16px;
`;