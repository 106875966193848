import styled from "styled-components";
import showPasswordIcon from '../../assets/icons/password-eye.svg';

export const ShowPassword = ({ onToggleActive, active }) => (
    <StyledShowPassword
        width="14"
        height="10"
        viewBox="0 0 14 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onToggleActive}
        active={active}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 0C3.81818 0 1.10091 1.97909 0 4.77273C1.10091 7.56636 3.81818 9.54545 7 9.54545C10.1818 9.54545 12.8991 7.56636 14 4.77273C12.8991 1.97909 10.1818 0 7 0ZM7.00018 7.95454C5.24381 7.95454 3.81836 6.52909 3.81836 4.77273C3.81836 3.01636 5.24381 1.59091 7.00018 1.59091C8.75654 1.59091 10.182 3.01636 10.182 4.77273C10.182 6.52909 8.75654 7.95454 7.00018 7.95454ZM5.09082 4.77237C5.09082 3.71601 5.94355 2.86328 6.99991 2.86328C8.05627 2.86328 8.909 3.71601 8.909 4.77237C8.909 5.82874 8.05627 6.68146 6.99991 6.68146C5.94355 6.68146 5.09082 5.82874 5.09082 4.77237Z"
            fill="#767782"
        />
    </StyledShowPassword>

)

const StyledShowPassword = styled.svg`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
    transition: all .3s;
    ${props => props.active && 'fill: #F4F7A1 !important;'}
    path {
        transition: all .3s;
        ${props => props.active && 'fill: #F4F7A1 !important;'}
    }
`;