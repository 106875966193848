import axios from "axios";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { baseUrl, headers } from "../../constants/api";
import { TERMS } from "../../constants/types";
import { useUser } from "../user/useUser";

export const useGetAllTerms = () => {
    const client = useQueryClient();
    const currentUserId = atob(localStorage.getItem('CUID'));

    // handle loading more terms
    const [loadingMore, setLoadingMore] = useState(false);
    const { data: terms = null } = useQuery(
        [TERMS],
        async () => (await axios.get(`${baseUrl}/api/terms/get-all-terms`, {
            headers: headers(),
            params: {
                sortDesc: true,
                sortBy: 'term_of_day',
                user_id: currentUserId
            }
        }))?.data?.response,
    );


    const handleLoadMoreTerms = async () => {
        setLoadingMore(true);
        let termsData = client.getQueryData(TERMS);
        termsData = termsData ?? {};
        const resp = await axios.get(`${baseUrl}/api/terms/get-all-terms`, {
            headers: headers(),
            params: {
                sortDesc: true,
                sortBy: 'id',
                page: termsData?.current_page + 1,
                user_id: currentUserId
            }
        })
        const nextPage = resp?.data?.response;
        let updatedTerms = nextPage;
        updatedTerms.data = [...termsData.data, ...nextPage.data];
        client.setQueriesData(TERMS, updatedTerms);
        setLoadingMore(false);
    }

    return {
        terms,
        onLoadMoreTerms: handleLoadMoreTerms,
        loadingMoreTerms: loadingMore
    }
}

export default useGetAllTerms;