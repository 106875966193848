import { useEffect, useState } from "react";
import styled from "styled-components";
import arrowTop from '../../assets/icons/arrow-top.svg';

export const ScrollTop = () => {
    const [scroled, setScroled] = useState(false);
    const handleScrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });;

    const handleCheckScrollHeight = () => {
        const scroledHeight = window.pageYOffset;
        setScroled(scroledHeight > 100);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleCheckScrollHeight, false);
        return () => {
            window.removeEventListener('scroll', handleCheckScrollHeight, false)
        }
    }, [])

    return (
        <StyledScrollTop
            visible={scroled}
            onClick={handleScrollToTop}
        >
            <img src={arrowTop} />
        </StyledScrollTop>
    )
}

const StyledScrollTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 2px solid #F28569;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all .5s;
    visibility: ${props => props.visible ? 'visivle' : 'hidden'};
    opacity: ${props => props.visible ? '1' : '0'};
`;