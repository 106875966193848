import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useUser } from "../../hooks/user/useUser";

export const LoginButton = () => {
  const { user } = useUser();
  const token = localStorage.getItem('token');

  return (
    <NavLink to={(user || token) ? `/profile/${user?.id}` : '/auth/login'}>
      <StyledLogin>
        {user ? 'Профиль' : 'Войти'}
      </StyledLogin>
    </NavLink>
  )
}

const StyledLogin = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  height: 50px;
  border: 1px solid #F4F7A1;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #232323;
  margin-left: 16px;
  color: #FFFFFF;
  background: none;
  cursor: pointer;
  transition: all .5s;
  &:hover {
    background: #F4F7A1;
    color: #232323;
  }
`;