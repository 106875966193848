import styled from "styled-components";
import { CheckBox } from "../CheckBox/CheckBox";
import { Input } from "../Input/Input";
import { Divider } from "./Didider";
import { LoginButton } from "./LoginButton";
import { Title } from "./Title";
import googleIcon from '../../assets/icons/google.svg';
import telegramIcon from '../../assets/icons/telegram.svg';
import { NewProfile } from "./NewProfile";
import { SocMediaButton } from "../SocMediaButton/SocMediaButton";
import { useState } from "react";
import { useLogin } from "../../hooks/auth/useLogin";
import { useNavigate } from "react-router-dom";
import { useLoginByGoogle } from "../../hooks/auth/useLoginByGoogle";
import { useEffect } from "react";
import jwtDecode from "jwt-decode";
import { useUser } from "../../hooks/user/useUser";
import { baseUrl } from "../../constants/api";

export const Login = ({ onSuccessAuth }) => {
  const navigate = useNavigate();
  const { login } = useLogin();
  const { loginByGoogle } = useLoginByGoogle();
  const { refetchUser } = useUser();
  // values
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [rememberPassword, setRememberPassword] = useState(false);
  const [error, setError] = useState(null);

  // on change username input
  const handleChangeUserName = (value) => {
    setUserName(value);
    setError(null);
  };

  // on change password input
  const handleChangePassword = (value) => setPassword(value);

  // toggle remember password
  const handleToggleRememberPassword = () => setRememberPassword(!rememberPassword);

  // on submit login
  const handleSubmit = async () => {
    const resp = await login({ login: userName, password });
    if (resp.status === 200) {
      const prevUrl = localStorage.getItem('prevUrl');
      localStorage.setItem('token', resp?.data?.response?.access_token);
      navigate(prevUrl ?? '/');
      onSuccessAuth('Успешный вход');
      localStorage.removeItem('prevUrl');
    } else if (resp?.status === 429) {
      const errorMessage = resp?.data?.response?.message;
      const isBlocked = errorMessage === 'User blocked';
      setError(isBlocked ? "Вас заблокировано" : errorMessage);
    } else {
      setError(resp?.data?.errors?.email || resp?.data?.error.errors)
    }
  }

  // response get by google auth
  const handleGoogleAuth = async (response) => {
    const data = jwtDecode(response?.credential);
    // checking has user already registrated
    const loginResponse = await loginByGoogle({ email: data?.email, sub: data?.sub });
    if (loginResponse.status === 200) {
      localStorage.setItem('token', loginResponse?.data?.response?.access_token);
      navigate('/');
      onSuccessAuth('Успешный вход');
    } else if (loginResponse?.status === 429) {
      const errorMessage = loginResponse?.data?.response?.message;
      const isBlocked = errorMessage === 'User blocked';
      isBlocked && onSuccessAuth("Вас заблокировано");
    } else {
      onSuccessAuth(loginResponse?.data?.errors?.email || loginResponse?.data?.errors.sub)
    }
  }

  // google init
  useEffect(() => {
    /*global google*/
    google.accounts.id.initialize({
      client_id: '922735540701-qhoubcnv5fpfehe129tbu322j8a3s3b2.apps.googleusercontent.com',
      callback: handleGoogleAuth
    })

    // adding google auth button
    const singUpButton = document.querySelector('#google-login-btn');
    if (singUpButton) {
      google.accounts.id.renderButton(singUpButton, {
        theme: 'dark',
        size: 'large',
        width: '300px',
        height: '60px'
      })
    }
  }, [])

  // login on press enter
  const handleLoginOnPressEnter = (e) => {
    if (e.key === 'Enter' && userName.length > 0 && password.length > 0) {
      handleSubmit();
    }
  }
  useEffect(() => {
    window.addEventListener('keydown', handleLoginOnPressEnter, false);
    return () => window.removeEventListener('keydown', handleLoginOnPressEnter, false);
  })

  // chek is there a auth token 
  useEffect(() => {
    const href = window.location.href;
    const urlParams = new URLSearchParams(href);
    const userId = href
      ?.split('?')[1]
      ?.split('&')[0]
      ?.split('=')[1];

    if (userId) {
      localStorage.setItem('token', urlParams.get("token"));
      localStorage.setItem('CUID', btoa(userId));
      refetchUser();
      onSuccessAuth('Успех!')
      navigate(`/profile/${userId}`);
      setTimeout(refetchUser, 1000)
    }
  }, [])



  // login telegram
  useEffect(() => {
    // login telegram button
    let script = document.createElement('script');
    script.async = true;
    script.src = 'https://telegram.org/js/telegram-widget.js?19';
    script.id = "telegramLoginBtn"
    script.setAttribute('data-telegram-login', 'Speach_ukBot');
    script.setAttribute('data-size', 'medium');
    script.setAttribute('data-onauth', `onTelegramAuth(user)`);
    script.setAttribute('data-request-access', 'write');
    document.getElementById("laravel3bot").appendChild(script)

    // login telegram callback
    let callback = document.createElement('script');
    callback.id = "telegramLoginCallback"
    callback.innerHTML = `
        function onTelegramAuth(user) {
          const data = {
            id: user?.id,
            first_name: user?.first_name,
            last_name: user?.last_name,
            username: user?.username,
            photo_url: user?.photo_url,
            auth_date: user?.auth_date,
            hash: user?.hash,
          };
          
          fetch('${baseUrl}/api/auth/telegram-login', {
            method: 'POST', 
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': 'https://speach.uk',
              'Access-Control-Allow-Methods': 'POST, GET, OPTIONS'
            }, 
            body: JSON.stringify(data)
          })
          .then(resp => resp.json())
          .then(resp => {
            if(resp?.code === 200) {
              const userData = resp?.response?.user;
              const userId = btoa(userData?.id);
              localStorage.setItem('CUID', userId);
              localStorage.setItem('token', resp?.response?.access_token);
              window.location.href = window.location.origin + '/#/success-telegram';
            }
          })
        }
        `;
    document.getElementById("laravel3bot").appendChild(callback)

    return () => {
      document.getElementById("telegramLoginBtn")?.remove();
      document.getElementById("telegramLoginCallback")?.remove();
    }

  }, [])


  return (
    <StyledLogin>
      <Title title="Войти" />
      <Input
        label="Юзернейм или почта"
        value={userName}
        error={error}
        onValueChange={handleChangeUserName}
        classes="input"
      />
      <Input
        label="Пароль"
        value={password}
        onValueChange={handleChangePassword}
        password
        classes="input"
      />
      <CheckBox
        label={[
          { type: 'text', text: 'Запомнить пароль' },
          { type: 'link', href: '/auth/forgot-password', text: 'Забыли пароль?', linkType: 'navLink' }
        ]}
        checked={rememberPassword}
        onToggle={handleToggleRememberPassword}
        disabled={userName.length === 0 || password.length === 0}
        classes="checkbox"
      />
      <LoginButton
        disabled={userName.length === 0 || password.length === 0}
        onSubmit={handleSubmit}
      />
      <Divider />
      <SocMediaButton
        logo={googleIcon}
        label="Войти с Google"
        classes="socmedia"
        id="google-login-btn"
      />
      <SocMediaButton
        logo={telegramIcon}
        label="Войти с Telegram"
        link="#"
        classes="socmedia"
        id="laravel3bot"
      />
      <NewProfile />

    </StyledLogin>
  )
}

const StyledLogin = styled.div`
  width: 100%;
  .input {
    margin-bottom: 16px;
  }
  .checkbox {
    margin-bottom: 10px;
    label {
      width: 100%;
      justify-content: space-between;
    }
  }
  .socmedia {
    margin-bottom: 16px;
  }
  #laravel3bot {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    height: 100% !important;
    cursor: pointer;
  }
`;