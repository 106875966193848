import axios from "axios";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { baseUrl, headers } from "../../constants/api";
import { CATEGORY_TERMS, PROFILE, TERM, TERMS, USER, USERT_TERMS } from "../../constants/types";
import { checkIsDataExist } from "../../helpers/checkIsDataExist";
import { updatetItem } from "../../helpers/updatetItem";
import { useUpdateProfileStatistics } from "../profile/useUpdateProfileStatistics";

export const useToggleFavoriteTerm = () => {
    const client = useQueryClient();
    const { userId } = useParams();
    const currentUserId = atob(localStorage.getItem('CUID'));
    const { updateProfileStatics } = useUpdateProfileStatistics();


    const handleToggleFavoriteTerm = async (termId) => {
        const resp = await axios.post(`${baseUrl}/api/user/add-favorite-term`, null, {
            headers: headers(),
            params: { term_id: termId }
        })

        if (resp?.status === 200) {
            let message;
            const favoriteStatus = resp?.data?.response?.status;

            // update dashboard favorites
            const allTermsData = client.getQueryData(TERMS);
            if (allTermsData) {
                let updatedAllTermsData = allTermsData;
                updatedAllTermsData.data = updatetItem(updatedAllTermsData.data, termId, { is_favorite: favoriteStatus });
                client.setQueriesData(TERMS, updatedAllTermsData);
            }

            // update user favorites
            let userTermsData = client.getQueriesData(USERT_TERMS);
            userTermsData = checkIsDataExist(userTermsData);
            if (userTermsData) {
                message = 'success';
                let updatedUserTermsData = userTermsData;
                updatedUserTermsData.data = updatetItem(updatedUserTermsData.data, termId, { is_favorite: favoriteStatus });
                client.setQueriesData(USERT_TERMS, updatedUserTermsData);
            }

            // update category terms
            let categoryTermsData = client.getQueriesData(CATEGORY_TERMS);
            categoryTermsData = checkIsDataExist(categoryTermsData);
            if (categoryTermsData) {
                let updatedCategoryTermsData = categoryTermsData;
                updatedCategoryTermsData.data = updatetItem(updatedCategoryTermsData.data, termId, { is_favorite: favoriteStatus });
                client.setQueriesData(CATEGORY_TERMS, updatedCategoryTermsData);
            }

            // update term favorites 
            let termData = client.getQueriesData(TERM);
            termData = checkIsDataExist(termData)
            if (termData && termData?.id === termId) {
                let updatedTermData = termData;
                updatedTermData.is_favorite = favoriteStatus;
                client.setQueriesData(TERM, updatedTermData);
            }

            // update profile statistics 
            updateProfileStatics();

            return message;
        }
    }

    return { toggleFavoriteTerm: handleToggleFavoriteTerm }
}