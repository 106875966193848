import styled from "styled-components"
import { Button } from "./Button";
import { CloseIcon } from "./CloseIcon";
import { Link } from "./Link";
import { TermCard } from "./TermCard";
import { Title } from "./Title";
import domtoimage from "dom-to-image";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { Warning } from "./Warning";

export const Modal = ({
  onClose,
  termId,
  title,
  description,
  sample,
  owner,
  ownerLikesCount,
  onPopupMessage
}) => {
  const cardsRef = useRef();
  const [parsedDescription, setParsedDescription] = useState([]);

  const handleParseHtml = (text) => {
    if (text) {
      const regex = /<[a-zA-Z0-9]*>([^<.*>;]*)<\/[a-zA-Z0-9]*>/gmi;
      console.log(text)
      return text
        .replace(/&nbsp;/g, '')
        .replace('<ol>', '')
        .replace('</ol>', '')
        .replace('<li>', '')
        .replace('</li>', '')
        .replace('<p>', '')
        .replace('</p>', '</br>')
        .replace('\n', '')
        .split(regex)
        .join('')
    }
    return '';
  }

  const handleParseDescription = () => {
    let splitedParagraphs = `${handleParseHtml(description)} ${(!sample || (sample === '<p><br></p>')) ? '' : `<p><i>${handleParseHtml(sample)} </i></p>`}`;
    const descriptionLength = splitedParagraphs.length;
    const text = [];
    // split description on cards
    for (let i = 0; i <= descriptionLength; i) {
      if (i === 0) {
        const titleLength = title.length;
        const titleLines = Math.floor(titleLength / 20);
        const lineLength = 25;
        const textLines = 9 - titleLines;
        let index = splitedParagraphs.substring(textLines * lineLength).indexOf(' ');
        let indexSpaceEndTag = 0;
        const isOpenLinkTag = splitedParagraphs.substring(0, textLines * lineLength + index).indexOf('<a');
        const isTagClosed = splitedParagraphs.substring(0, textLines * lineLength + index).indexOf('</a>');
        const isBrTagNear = splitedParagraphs.substring(0, textLines * lineLength + index).indexOf('</b>');


        if (isOpenLinkTag !== -1 && isTagClosed === -1) {
          indexSpaceEndTag = splitedParagraphs.substring(textLines * lineLength + index).indexOf('</a>');
          index = splitedParagraphs.substring(textLines * lineLength + indexSpaceEndTag).indexOf(' ')
        }

        if (isBrTagNear !== -1 && isBrTagNear <= 10) {
          index += isBrTagNear;
        }

        text.push(`${splitedParagraphs.substring(i, textLines * lineLength + index + indexSpaceEndTag)} ${splitedParagraphs.substring(textLines * lineLength + index + indexSpaceEndTag + i)?.length > 0 ? '>>>' : ''}`)
        i += textLines * lineLength + index + indexSpaceEndTag;
      } else {
        // check index for break words
        let indexSpaceEnd = splitedParagraphs.substring(330 + i).indexOf(' ');
        let isOpenLinkTag = splitedParagraphs.substring(i, 330 + i).indexOf('<a');
        const isTagClosed = splitedParagraphs.substring(i, 330 + i).indexOf('</a>');
        const isBrTagNear = splitedParagraphs.substring(i).indexOf('</b>');

        if (isOpenLinkTag !== -1 && isTagClosed === -1) {
          const indexSpaceEndTag = splitedParagraphs.substring(330 + i).indexOf('</a>');
          indexSpaceEnd += indexSpaceEndTag
        }

        if (isBrTagNear !== -1 && isBrTagNear <= 10) {
          indexSpaceEnd += isBrTagNear;
        }
        // console.log(splitedParagraphs)
        splitedParagraphs.substring(i, 330 + i)?.length > 0 && text.push(`${splitedParagraphs.substring(i, 330 + indexSpaceEnd + i)} ${splitedParagraphs.substring(350 + indexSpaceEnd + i)?.length > 0 ? '>>>' : ''}`)
        i += 330 + indexSpaceEnd;
      }
    }
    setParsedDescription(text);
  }

  // html to base64/png
  const handleHtmlToPng = () => {
    const scale = 4;
    const cards = cardsRef.current?.children[0]?.children;

    for (const card of cards) {
      const cardIndex = card.id.split("-")[2];

      setTimeout(() => domtoimage.toPng(card, {
        width: card.clientWidth * scale,
        height: card.clientHeight * scale,
        style: {
          transform: 'scale(' + scale + ')',
          transformOrigin: 'top left'
        }
      })
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.setAttribute(
            'download',
            `${title.toLowerCase().split(' ').join('_')}_${cardIndex}.png`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }), 1100)
    }
  }
  useEffect(() => {
    handleParseDescription()
  }, [description])

  const handleCheckIsiOS = () => {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  return (
    <StyledModal isOneCard={parsedDescription.length === 1}>
      <CloseIcon onClose={onClose} />
      <Title title="Вы можете загрузить карточку как изображение (1080х1080) к себе на устройство и делиться ей в социальных сетях." />
      <div ref={cardsRef}>
        <div className="cards-wrapper">
          <div id="term-card-1">
            <TermCard
              title={title}
              descripton={parsedDescription[0]}
              owner={owner}
              ownerLikesCount={ownerLikesCount}
              mainCard
              index={1}
            />
          </div>
          {
            parsedDescription
              .slice(1,)
              .map((descr, i) => (
                <div id={`term-card-${2 + i}`}>
                  <TermCard
                    key={i}
                    descripton={descr}
                  />
                </div>
              ))
          }
        </div>
      </div>
      <Button onClick={handleHtmlToPng} />
      {
        handleCheckIsiOS() && <Warning />
      }
      <Title title="Либо же используйте эту ссылку:" />
      <Link onPopupMessage={onPopupMessage} termId={termId} />
    </StyledModal >
  )
}

const StyledModal = styled.div`
  padding: 32px 0;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid #4D4A48;
  backdrop-filter: blur(236px);
  border-radius: 24px;
  max-width: 926px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: max-content;
  margin: 20px 0;
  position: relative;
  .cards-wrapper {
    display: grid;
    grid-template-columns: 250px;
    grid-auto-rows: 250px;
    width: 90%;
    justify-items: center;
    margin-top: 24px;
    gap: 10px;
  }
  @media(min-width: 480px) {
    padding: 32px 6px;
    .cards-wrapper {
      gap: 13px;
      width: auto;
      grid-auto-rows: minmax(204px, 1fr);
      grid-template-columns: ${props => props.isOneCard ? '204px' : '1fr 1fr'};
    }
  }
  @media(min-width: 768px) {
    padding: 40px 30px;
    .cards-wrapper {
      gap: 16px;
      grid-auto-rows: 250px;
    }
  }
`;