import { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";

export const Content = ({ fullText, description, sample, termId, slug }) => {
    const [showMore, setShowMore] = useState(fullText);
    const contentRef = useRef();
    const sampleRef = useRef();
    const navigate = useNavigate();

    const handleHideContent = () => {
        const regex = /<[a-zA-Z0-9]*>([^<.*>;]*)<\/[a-zA-Z0-9]*>/gmi;
        const splitedParagraphs = description
            .replace(/&nbsp;/g, '')
            .replace('<ol>', '<p><ol>')
            .replace('</ol>', '</ol></p>')
            .replace('<li>', '')
            .replace('</li>', '')
            .split(regex)
            .filter(x => x.trim() !== '');
        const firstParagraph = splitedParagraphs[0]
        const secondParagraph = splitedParagraphs[1]

        if (firstParagraph && firstParagraph.length > 150) {
            const spaceIndex = firstParagraph.substring(150,).indexOf(' ');
            return firstParagraph.substring(0, 150 + spaceIndex);
        } else if (firstParagraph && secondParagraph) {
            const spaceIndex = secondParagraph.substring(60,).indexOf(' ');
            return `<p>${firstParagraph}</p>${secondParagraph.substring(0, 60 + spaceIndex)}<p></p>`;
        } else {
            setShowMore(true);
            return splitedParagraphs;
        }
    }

    useEffect(() => {
        if (description && fullText) {
            contentRef.current.innerHTML = description;
        } else if (description) {
            const text = handleHideContent();
            contentRef.current.innerHTML = text;
        }
        if (sample && sampleRef.current) {
            sampleRef.current.innerHTML = sample;
        }
    }, [description, sample, sampleRef.current])

    const handleClickOnTerm = (e) => {
        const element = e.target.nodeName;
        if (element !== 'A') {
            navigate(`/opredelenie-slova/${slug}`);
        }
    }

    return (
        <StyledContent onClick={handleClickOnTerm} className="term-card-content-wrapper">
            <StyledDescription
                showMore={showMore}
                ref={contentRef}
                className="not-select"
            />
            {!showMore &&
                <>
                    ...
                    <NavLink to={`/opredelenie-slova/${slug}`} rel="canonical">
                        <StyledMore>
                            Еще
                        </StyledMore>
                    </NavLink>

                </>}
            {
                (sample && sample !== '<p><br></p>' && showMore) &&
                <>
                    <br />
                    <StyledSample ref={sampleRef} />
                </>
            }
        </StyledContent >
    )
}
const StyledContent = styled.div`
    margin: 20px 0;
    font-weight: 400;
    font-size: 17px;
    line-height: 140%;
    color: #D5D2D0;
    word-break: break-all;
    cursor: pointer;
    a {
        color: #FDFDFD;
        text-decoration: underline;
    }
    p {
      margin: 0;
    }
`;

const StyledDescription = styled.span`
    word-break: break-word;
`;

const StyledSample = styled.div`
    font-style: italic;
    margin-top: 10px;
    word-break: normal;
`;

const StyledMore = styled.span`
    word-break: normal;
    margin-left: 5px;
    font-weight: 600;
    color: #D5D2D0;
    transition: all .5s;
    cursor: pointer;
    &:hover {
        color: #F4F7A1;
    }
`;