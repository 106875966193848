import styled from "styled-components";
import { Card } from "./Card";
import Marquee from "react-fast-marquee";
import { useTopTerms } from "../../hooks/term/useTopTerms";

export const StatisicsCards = () => {
    const { topTerms } = useTopTerms();

    return (
        <StyledStatisicsCards>
            <Marquee
                pauseOnHover={true}
                speed={50}
                gradient={false}
                loop={0}
            >
                {
                    topTerms?.length > 0 &&
                    topTerms.map((term, i) => (
                        <Card
                            key={i}
                            termId={term?.slug}
                            title={term?.title}
                            procent={term?.likes}
                            statistic={term?.statistic}
                        />
                    ))
                }
            </Marquee>
        </StyledStatisicsCards>
    )
}

const StyledStatisicsCards = styled.div`
  margin: 32px 0;
  overflow: hidden;
`;