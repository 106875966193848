import axios from "axios"
import { baseUrl, headers } from "../../constants/api"
import { useMutation, useQueryClient } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { USER, USERT_TERMS } from "../../constants/types";
import { useUser } from "../user/useUser";
import { checkIsDataExist } from "../../helpers/checkIsDataExist";

export const useCreateTerm = () => {
    const client = useQueryClient();
    const { user } = useUser();
    const location = useLocation();
    const pathname = location.pathname.split('/');
    const isProfilePage = pathname[1] === 'profile';
    const profileId = pathname[2];

    const createTerm = (data) => {
        return axios.post(`${baseUrl}/api/term/add-term`, data, {
            headers: headers()
        })
            .then(resp => {
                const createdTerm = resp?.data?.response;
                // if current page is user page adding new terms
                if (isProfilePage && createdTerm?.user_id === Number(profileId)) {
                    let userTerms = client.getQueriesData(USERT_TERMS)[0][1];
                    userTerms = userTerms ?? {};
                    let updatedUserTerms = userTerms;
                    updatedUserTerms.data = [
                        {
                            ...createdTerm,
                            user: { role_id: user?.role_id, name: user?.login, likes: user?.statistic?.likes }
                        },
                        ...userTerms.data
                    ];
                    // update user not approved terms
                    let userData = client.getQueriesData(USER);
                    userData = checkIsDataExist(userData);
                    if ((userData.id === createdTerm?.user_id) && (userData.role_id === 1)) {
                        let updateUserData = userData;
                        updateUserData.my_approve_terms = [createTerm, ...updateUserData.my_approve_terms]
                        // client.setQueriesData(USER, updateUserData);
                    }
                    client.setQueriesData(USERT_TERMS, updatedUserTerms);
                }
                return resp;
            })
            .catch(error => error)
    }

    return { createTerm }
}