import axios from "axios";
import { useQueryClient } from "react-query";
import { baseUrl, headers } from "../../constants/api";
import { CATEGORY_TERMS, PROFILE, TERM, TERMS, USER, USERT_TERMS } from "../../constants/types";
import { checkIsDataExist } from "../../helpers/checkIsDataExist";
import { updatetItem } from "../../helpers/updatetItem";

export const useModerationTerm = () => {
    const client = useQueryClient();

    const hadndleModerationTerm = async ({ termId, status }) => {
        const resp = await axios.post(`${baseUrl}/api/term/verification-term`, null, {
            headers: headers(),
            params: {
                term_id: termId,
                status
            }
        })

        if (resp?.status === 200) {
            let userData = client.getQueryData(USER);
            if (userData && userData?.suggestion) {
                setTimeout(() => {
                    let updatedUserData = userData;
                    updatedUserData.suggestion.data = updatedUserData.suggestion.data.filter(t => t.id !== termId);
                    client.setQueryData(USER, updatedUserData);
                }, 1200)
            }
            return 'success'

        }

    }

    return { moderationTerm: hadndleModerationTerm }
}