import styled from "styled-components";
import { Message } from "./Message";
import arrowIcon from '../../assets/icons/step-arrow-right.svg';
import approveIcon from '../../assets/icons/approve.svg';
import questionIcon from '../../assets/icons/question.svg';
import notAprroveIcon from '../../assets/icons/not-approve.svg';

export const Step4 = ({ visible, onNextInfoStep }) => (
    <StyledStep3 visible={visible} onClick={onNextInfoStep}>
        <Message>
            <StyledContent>
                В категорию “Нужно определить” вы <br />
                можете добавлять слова, которые, по <br />
                вашему мнению будут актуальны в <br />
                рамках ресурса Speach.
            </StyledContent>
        </Message>
        <StyledArrow src={arrowIcon} />
    </StyledStep3>

)

const StyledStep3 = styled.div`
    position: absolute;
    z-index: 100;
    flex-shrink: 0;
    display: flex;
    width: 290px;
    top: -250px;
    left: 0px;
    transition: all 2s;
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    opacity: ${props => props.visible ? '1' : '0'};
    z-index:  ${props => props.visible ? '100' : '-100'};
    transform: translateY(${props => props.visible ? 0 : -100}px);
    @media(min-width: 360px) {
        width: 340px;
        top: -340px;
    }
    @media(min-width: 480px) {
        width: 400px;
        top: -270px;
    }
    @media(min-width: 660px) {
        width: 520px;
        top: -190px;
    }
    @media(min-width: 1080px) {
        left: -100px;
        top: -190px;
    }
    @media(min-width: 1400px) {
        left: -200px;
    }
`;

const StyledContent = styled.div`
    line-height: 150%;
    width: 100%;
    img {
        height: 15px;
        margin-right: 10px;
    }
`;


const StyledArrow = styled.img`
    margin:  auto 0 0 51px ;
    height: auto !important;
`;