import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { ADMIN, USER } from "../../constants/roles";
import { hideModalOnClickOutside } from "../../helpers/hideModalOnClickOutside";
import useCategories from "../../hooks/categories/useCategories";
import { useCreateTerm } from "../../hooks/term/useCreateTerm";
import { useUser } from "../../hooks/user/useUser";
import { BackgroundGradient } from "../BackgroundGradient/BackgroundGradient";
import { Input } from "../Input/Input";
import { Logo } from "../Logo/Logo";
import { Select } from "../Select/Select";
import { TextEditor } from "../TextEditor/TextEditor";
import { Button } from "./Button";
import { Info } from "./Info";
import { InfoModal } from "./InfoModal";
import { Title } from "./Title";

export const CreateTerm = ({ onPopupMessage }) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { termTitle } = useParams();
  const { createTerm } = useCreateTerm();
  const { categories } = useCategories();
  const [showInfo, setShowInfo] = useState(false);
  const [name, setName] = useState('');
  const [category, setCategory] = useState(null);
  const [description, setDescription] = useState({ htmlValue: '', textValue: '' });
  const [example, setExample] = useState({ htmlValue: '', textValue: '' });
  const [errors, setErros] = useState({});
  const [loading, setLoading] = useState(false);
  const goingBack = useRef(false);
  // changing values
  const handleChangeName = (value) => {
    setName(value);
    setErros({ ...errors, name: value.length > 100 ? "Название - максимум 100 символов" : null })
  }
  const handleChangeCategory = (value) => setCategory(value);
  const handleChangeDescription = (value) => {
    setDescription(value);
    setErros({ ...errors, description: value.textValue.length > 5000 ? "Описание - максимум 5000 символов" : null })
  };

  const handleChangeExample = (value) => {
    setExample(value)
  };

  // show modal about terms
  const handleToggleShowInfo = (value) => setShowInfo(value);

  // reset all values
  const handleResetValues = () => {
    setName('');
    setCategory(null);
    setDescription('');
    setExample('');
  }

  const handleCloseCreateTerm = () => {
    if (!goingBack.current) {
      goingBack.current = true;
      navigate(-1);
    }
  }
  // create new term
  const handleCreateTerm = async () => {
    setLoading(true);
    const resp = await createTerm({
      title: name,
      category_id: category?.value,
      description: description?.htmlValue,
      sample: example?.htmlValue
    });
    if (resp?.status === 200) {
      handleResetValues();
      handleCloseCreateTerm();
      setLoading(false);
      onPopupMessage(user?.role_id !== ADMIN ? "Отправлено на подтверждение" : "Термин успешно добавленo");
    } else {
      // checking is there any error
      const nameError = resp?.response?.data?.errors?.title && resp?.response?.data?.errors?.title[0];
      setErros({ ...errors, name: nameError });
    }
  }

  // checking is it editing and set values
  useEffect(() => {
    setName(termTitle ?? '')
  }, [termTitle])

  // reset data on close modal
  useEffect(() => {
    return () => handleResetValues();
  }, [])

  // cheking click outside modal 

  useEffect(() => {
    window.addEventListener("click", (e) => {
      hideModalOnClickOutside(e, "create-term-modal-wrapper", handleCloseCreateTerm);
    }, false);

    return () => window.removeEventListener("click", (e) => {
      hideModalOnClickOutside(e, "create-term-modal-wrapper", handleCloseCreateTerm);
    }, false);
  }, [])

  return (
    <StyledCreateTerm className="create-term-modal-wrapper">
      <Logo noLink />
      {
        showInfo
          ? <InfoModal onCloseModal={handleToggleShowInfo} />
          : <StyledContent>
            <Title title="Добавить термин" />
            <Input
              label="Название"
              value={name}
              onValueChange={handleChangeName}
              error={errors?.name}
            />
            <Select
              label="Категория"
              options={categories.map(c => ({ label: c?.title, value: c.id }))}
              value={category}
              onValueChange={handleChangeCategory}
              classes="select"
            />
            <TextEditor
              label="Описание"
              placeholder="5000 символов макс."
              classes="input-desription"
              onChange={handleChangeDescription}
              error={errors?.description}
            />
            <TextEditor
              label="Пример"
              placeholder="Необязательно"
              classes="input-example"
              onChange={handleChangeExample}
            />
            <Info onShowInfo={handleToggleShowInfo} />
            <Button
              text="Добавить"
              onCreate={handleCreateTerm}
              disabled={name.length === 0 || description?.textValue?.trim()?.length === 0 || errors?.description || loading || errors?.name}
            />
          </StyledContent>
      }

      <BackgroundGradient />
    </StyledCreateTerm>
  )
}

const StyledCreateTerm = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #232323;
  min-height: 100vh;
  z-index: 1000;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 60px 0 100px;
  overflow: scroll;
  transition: all .5s;
  width: 100%;
  overflow-x: hidden;
  .select{
    margin: 16px 0;
  }
  .input-desription {
    margin-bottom: 16px;
    height: max-content;
  }
  .input-example {
    margin-bottom: 18px;
    height: max-content;
    font-style: italic;
  }
`;

const StyledContent = styled.div`
  width: 90%;
  box-sizing: content-box;
  @media (min-width: 360px) {
    width: 320px;
  }
  @media (min-width: 480px) {
    width: 340px;
    padding: 0 10%;
  }
`;