import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { EditTerm } from "../components/EditTerm/EditTerm";
import { Loader } from "../components/Loader/Loader";
import { LoadMore } from "../components/LoadMore/LoadMore";
import { NoTermins } from "../components/NoTermins/NoTermins";
import { Spinner } from "../components/Spinner/Spinner";
import { TermCard } from "../components/TermCard/TermCard";
import { WhatRegistration } from "../components/WhatRegistration/WhatRegistration";
import { ADMIN } from "../constants/roles";
import { setTabTitle } from "../helpers/setTabTitle";
import useGetAllTerms from "../hooks/term/useGetAllTerms";
import { useUser } from "../hooks/user/useUser";
import { useArticles } from "../hooks/article/useArticles";
import { ArticleCard } from "../components/ArticleCard/ArticleCard";
import { useLoginByTelegram } from "../hooks/auth/useLoginByTelegram";
import { GoogleAd } from "../components/GoogleAd/GoogleAd";
import { useRef } from "react";
import { setPageDescription } from "../helpers/setPageDescription";

export const Dashboard = ({ onPopupMessage }) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { user, refetchUser } = useUser();
  const { terms, onLoadMoreTerms, loadingMoreTerms } = useGetAllTerms();
  const { articles } = useArticles();
  const { loginByTelegram } = useLoginByTelegram();
  // edit term by admin modal toggle
  const [editTerm, setEditTerm] = useState(false);
  const [whatRegistrationModal, setWhatRegistrationModal] = useState(false);
  const [viewWidth, setViewWidth] = useState(null);
  const [scrollOffSet, setScrollOffSet] = useState(0);
  const articlesRef = useRef();

  // close WhatRegistration modal
  const handleCloseWhatRegistration = () => {
    setWhatRegistrationModal(false);
  };

  // set tab title
  useEffect(() => {
    setTabTitle('Speach');
    setPageDescription();
  }, [])

  useEffect(() => {
    window.addEventListener('resize', () => setViewWidth(window.innerWidth), false);
    return () => window.addEventListener('resize', () => setViewWidth(window.innerWidth), false)
  }, [])

  const handleEditTerm = (value) => setEditTerm(value);

  // checking is it what registration page and show warning modal
  useEffect(() => {
    const pathname = location.pathname.split('/');
    const isWhatRegistrationMessage = pathname[1] === 'what-registration';
    isWhatRegistrationMessage && setWhatRegistrationModal(true);

    const isTelegramAuth = pathname[1] === 'success-telegram';
    if (isTelegramAuth) {
      onPopupMessage('Успех');
      refetchUser();
    }
    const urlParams = new URLSearchParams(window.location?.search);
    const telegramLoginToken = urlParams.get("token");
    if (telegramLoginToken) {
      setLoading(true);
      loginByTelegram({ token: telegramLoginToken })
        .then(resp => {
          setLoading(false);
          onPopupMessage('Успех');
          window.location.replace('/')
        })
    }
  }, [])

  const handleCheckScrollOffSet = (e) => {
    const topOffset = window.pageYOffset || document.documentElement.scrollTop;
    setScrollOffSet(topOffset);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleCheckScrollOffSet, false);
    return () => window.addEventListener('scroll', handleCheckScrollOffSet, false);
  }, [])

  return (
    <>
      <WhatRegistration
        visible={whatRegistrationModal}
        onClose={handleCloseWhatRegistration}
        onPopupMessage={onPopupMessage}
      />
      <EditTerm
        visible={editTerm}
        onClose={() => handleEditTerm(false)}
        onPopupMessage={onPopupMessage}
      />
      <div>
        <StyledContent className="container">
          <StyledTermCards>
            {
              (!terms || loading)
                ? <Spinner />
                : <>
                  {
                    terms?.data?.length > 0
                      ? <>
                        {
                          terms?.data
                            .map((term, i) => {
                              return (
                                <>
                                  <TermCard
                                    key={i}
                                    title={term.title}
                                    termId={term.id}
                                    slug={term.slug}
                                    description={term.description}
                                    sample={term?.sample}
                                    owner={term.user}
                                    ownerId={term?.user_id}
                                    ownerLikesCount={term?.user?.likes ?? 0}
                                    currentUser={user}
                                    onEdit={() => handleEditTerm(term)}
                                    isTermOfDay={term?.term_of_day}
                                    isFavorite={term?.is_favorite}
                                    likeStatus={term?.like_status}
                                    lastUpdateDate={term?.created_at}
                                    adminView={user?.role_id === ADMIN}
                                    onPopupMessage={onPopupMessage}
                                  />
                                  {/* {
                                    i === 3 &&
                                    <GoogleAd />
                                  } */}
                                  {
                                    i === 1 &&
                                    <StyledArticlesInTerms>
                                      {
                                        articles.length > 0 &&
                                        articles
                                          ?.slice(0, viewWidth > 768 ? 2 : 1)
                                          ?.map((article, i) => (
                                            <ArticleCard
                                              key={i}
                                              id={article?.id}
                                              image={article?.image}
                                              description={article?.description}
                                              link={article?.link}
                                              onPopupMessage={onPopupMessage}
                                              noClose
                                            />
                                          ))
                                      }
                                    </StyledArticlesInTerms>
                                  }
                                </>
                              )
                            })
                        }
                      </>
                      : <NoTermins />
                  }
                </>
            }
            {
              terms?.current_page < terms?.last_page &&
              <LoadMore
                onClick={onLoadMoreTerms}
                loading={loadingMoreTerms}
              />
            }
          </StyledTermCards>
          <StyledArticles
            scrollOffSet={scrollOffSet}
            ref={articlesRef}
            wrapperWidth={articlesRef.current?.clientWidth}
          >
            {
              articles.length > 0 ?
                articles
                  ?.slice(0, 2)
                  ?.map((article, i) => (
                    <ArticleCard
                      key={i}
                      id={article?.id}
                      image={article?.image}
                      description={article?.description}
                      link={article?.link}
                      onPopupMessage={onPopupMessage}
                      noClose
                      styles={i === 1 && "second-article"}
                    />
                  ))
                : null
            }
          </StyledArticles>
        </StyledContent>
      </div>
    </>
  )
}

const StyledContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  position: relative;
  @media(min-width: 1200px) {
    grid-template-columns: 1fr 30%;
  }
`;

const StyledTermCards = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 13px;
  @media (min-width: 480px) {
    margin-top: 23px;
  }
  @media (min-width: 768px) {
    margin-top: 26px;
  }
  @media (min-width: 1000px) {
    margin-top: 32px;
  }
`;


const StyledArticles = styled.div`
  display: none;
  grid-auto-rows: 250px;
  margin-top: 32px;
  gap: 16px;
  min-height: 800px;
  overflow: hidden;
  @media(max-width: 1300px) {
    ${props => props.scrollOffSet > 350 && `
      .second-article {
        transition: all .3s;
        position: fixed;
        top: 160px;
        height: 250px;
        width: ${props.wrapperWidth}px;
      }
    `}
  }
  @media(min-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr;
  }
  @media(min-width: 1300px) {
    grid-auto-rows: 360px;
    ${props => props.scrollOffSet > 470 && `
    .second-article {
      position: fixed;
      top: 160px;
      height: 360px;
      width: ${props.wrapperWidth}px;
    }
  `}
  }
`;

const StyledArticlesInTerms = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 280px;
  gap: 8px;
  width: 100%;
  @media(min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media(min-width: 1200px) {
    display: none;
  }
`;